import { removeKeysWithBlankValues } from '@zoocasa/node-kit/objects/remove-keys';
import { provinceAndStateSlugs, provinceAndStateCodes, provinceOrStateSlugFromCode, provinceOrStateCodeFromSlug } from 'utils/province_or_state';
import { regexMatchInGroups, matchGroup } from '@zoocasa/node-kit/strings/regex-match-in-groups';
import dataJSON from './data.json';

export type RouteName = 'area-listings' | 'listing' | 'ember';
export type RouteMatchObject = {
  route: RouteName;
} & any;
type LocationType = 'province' | 'city' | 'neighbourhood' | 'street';
type MatchInfo = any;
type Route = any;
type HouseTypeFilter = any;
type RouteMatcher = any;

/**
 * @deprecated Use the new route matcher instead: src/components/dynamic-page/route-matchers/route-matchers.ts
 */
export function generateRouteMatchObjectFromPath(path: string): RouteMatchObject {
  if (!path && window.location) {
    path = window.location.pathname;
  }

  const routeMatchObject = {};
  buildRouteMatcherArray().find((routerMatcher: RouteMatcher) => {
    const regexStr = `^/?${routerMatcher.pathRegex}/?$`;
    if (new RegExp(regexStr.replace(/\?<(.+?)>/g, '')).test(path)) {
      const matchGroup = regexMatchInGroups(path, regexStr);
      const matchInfo = extractMatchInfoObject(matchGroup);
      const { route, locationType } = routerMatcher;
      Object.assign(routeMatchObject, { route: route, locationType: locationType });
      if (route === 'listing') {
        Object.assign(routeMatchObject, listingInfo(matchGroup));
      } else if (route === 'named-content') {
        Object.assign(routeMatchObject, { url: matchInfo.url });
      } else {
        const secondaryFilter = matchInfo.secondaryFilter || matchInfo.secondaryFilterNeighbourhood || matchInfo.secondaryFilterStreet;
        const { primaryFilterKey, primaryFilterValue } = buildPrimaryFilter(matchInfo.primaryFilter) || {};
        const { secondaryFilterKey, secondaryFilterValue } = buildSecondaryFilter(secondaryFilter) || {};

        Object.assign(routeMatchObject, matchInfo, {
          filter: {
            homeType: buildHomeType(matchInfo.homeType),
            rental: !!matchInfo.isRental?.startsWith('rent'),
            status: buildStatus(matchInfo.listingStatus),
            slug: buildSlug(locationType, matchInfo),
            ...(primaryFilterKey && { [primaryFilterKey]: primaryFilterValue }),
            ...(secondaryFilterKey && { [secondaryFilterKey]: secondaryFilterValue }),
          },
        });
      }
      return removeKeysWithBlankValues(routeMatchObject);
    }
  });
  return routeMatchObject;
}

export function buildSlug(locationType: LocationType, matchInfo: MatchInfo) {
  const { province, provinceCode, city, neighbourhood, street, secondaryFilter } = matchInfo;
  if (locationType === 'street') {
    const prefix = [neighbourhood, city, provinceCode].filter(i => i).join('-');
    return `${prefix}/${street}`;
  } else if (locationType === 'neighbourhood') {
    return `${neighbourhood}-${city}-${provinceCode}`;
  } else if (locationType === 'city' || (secondaryFilter && city)) {
    return `${city}-${provinceCode}`;
  } else if (locationType === 'province' || (secondaryFilter && province)) {
    return province;
  } else {
    return null;
  }
}

function buildRouteMatcherArray(): RouteMatcher[] {
  const areaPageSecondaryFilters = dataJSON.areaPageSecondaryFilters;
  return [
    { route: 'area-listings', pathRegex: `(?<city>[A-Za-z-0-9\\-]+)-(?<provinceCode>${provinceAndStateCodes.join('|')})-(?<homeType>real-estate)(?<isRental>-for-rent)?/(?<secondaryFilter>${areaPageSecondaryFilters.join('|')})` },
    { route: 'listing', pathRegex: `(?<city>[A-Za-z-0-9\\-]+)-(?<provinceCode>${provinceAndStateCodes.join('|')})-real-estate(?<isRental>-for-rent)?/(?<address>[a-z]*-?(?<listingId>\\d+)[\\w\\-]+)` },
    ...generateRouteMatchers('area-listings', `(?<listingStatus>sold-listings)(/(?<secondaryFilter>${areaPageSecondaryFilters.join('|')}))?`),
    ...generateRouteMatchers('area-listings', `(?<listingStatus>past-listings)(?:-for-(?<isRental>rent))?(/(?<secondaryFilter>${areaPageSecondaryFilters.join('|')}))?`),
    ...generateRouteMatchers('area-listings', `(?<homeType>real-estate|houses|townhouses|condos|(?<primaryFilter>open-houses))(?:-for-(?<isRental>rent))?(/(?<secondaryFilter>${areaPageSecondaryFilters.join('|')}))?`),
    ...generateRouteMatchers('area-listings', '(?<homeType>real-estate|houses|townhouses|condos|(?<primaryFilter>open-houses))(?:-for-(?<isRental>rent))?/(?<filterParams>filter)'),
    ...generateRouteMatchers('area-listings', `(?<homeType>recently-(?<listingStatus>sold)|recently-(?<isRental>rented))(/(?<secondaryFilter>${areaPageSecondaryFilters.join('|')}))?`),
    ...generateRouteMatchers('locations', '(?<isLocation>provinces|cities|neighbourhoods|streets)'),
    { route: 'named-content', pathRegex: '(?<url>.*)' },
  ];
}

function generateRouteMatchers(route: Route, matcherContent: string) {
  const provinceRegex = `(?<province>${provinceAndStateSlugs.join('|')})`;
  const listingsCityRegex = `(?<city>[A-Za-z-0-9\\-]+)-(?<provinceCode>${provinceAndStateCodes.join('|')})`;
  const locationsCityRegex = buildCityWithProvinceCode(provinceAndStateCodes.map(code => `.*?-${code}`));
  const cityThatHasNeighbourhoodRegex = buildCityWithProvinceCode(dataJSON.citiesWithNeighbourhoods);
  const neighbourhoodRegex = `(?<neighbourhood>[A-Za-z\\-]+)(/(?<secondaryFilterNeighbourhood>${dataJSON.areaPageSecondaryFilters.join('|')}))?`;
  const streetRegex = `(?:${neighbourhoodRegex}/)?(?<street>[A-Za-z\\-]+[\\dA-Za-z\\-]*)(/(?<secondaryFilterStreet>${dataJSON.areaPageSecondaryFilters.join('|')}))?`;

  const locationTypes = {
    country: {},
    province: { prefix: provinceRegex },
    city: { prefix: route === 'area-listings' ? listingsCityRegex : locationsCityRegex },
    neighbourhood: { prefix: cityThatHasNeighbourhoodRegex, suffix: neighbourhoodRegex },
    street: { prefix: locationsCityRegex, suffix: streetRegex },
  } as any;

  return Object.keys(locationTypes).map((locationType: any) => {
    let { prefix, suffix } = locationTypes[locationType];
    prefix = prefix ? `${prefix}-` : '';
    suffix = suffix ? `/${suffix}` : '';
    return {
      route: route,
      locationType: locationType === 'country' ? null : locationType,
      pathRegex: `${prefix}${matcherContent}${suffix}`,
    };
  });
}

function extractMatchInfoObject(matchGroup: matchGroup) {
  const matchInfo = Object.assign({}, matchGroup) as any;
  const cityWithProvinceCode = matchGroup.cityWithProvinceCode;
  if (cityWithProvinceCode) {
    // Re-fill the city & provinceCode for citiesWithNeighbourhoods which don't get captured by the regex
    const parts = cityWithProvinceCode.split('-');
    const provinceCode = parts.pop();
    const city = parts.join('-');
    Object.assign(matchInfo, { city: city, provinceCode: provinceCode });
  }
  if (!matchInfo.province && matchInfo.provinceCode) {
    matchInfo.province = provinceOrStateSlugFromCode(matchInfo.provinceCode.toLowerCase());
  } else if (!matchInfo.provinceCode && matchInfo.province) {
    matchInfo.provinceCode = provinceOrStateCodeFromSlug(matchInfo.province);
  }
  return matchInfo;
}

function listingInfo(matchGroup: matchGroup) {
  const isRental = !!matchGroup.isRental;
  const slugSuffix = [matchGroup.city, matchGroup.provinceCode].join('-');
  // The long listing url includes the slug suffix (ex. toronto-on) which
  // means that this is a single listing page and we can query by listingId
  if (matchGroup.address.match(slugSuffix) && !isRental) {
    return { listingId: matchGroup.listingId };
  } else { // otherwise we build a listingSlug from the url fragments
    return {
      listingSlug: [matchGroup.address, slugSuffix].join('-'),
      listingIsRental: isRental,
    };
  }
}

function buildHomeType(homeTypeFilter: HouseTypeFilter) {
  if (!homeTypeFilter || !['houses', 'townhouses', 'condos'].includes(homeTypeFilter)) {
    return {
      houseDetached: true,
      houseSemidetached: true,
      houseAttached: true,
      townhouse: true,
      condo: true,
    };
  }
  const homeType = {
    houseDetached: false,
    houseSemidetached: false,
    houseAttached: false,
    townhouse: false,
    condo: false,
  };
  if (homeTypeFilter === 'houses') {
    homeType.houseDetached = true;
    homeType.houseSemidetached = true;
    homeType.houseAttached = true;
  } else if (homeTypeFilter === 'townhouses') {
    homeType.townhouse = true;
  } else if (homeTypeFilter === 'condos') {
    homeType.condo = true;
  }
  return homeType;
}

function buildStatus(urlSnippet: string) {
  const match = statusMap().filter(x => x.urlSnippet === (urlSnippet || null))[0];
  if (match) {
    return match.status;
  }
}

function buildCityWithProvinceCode(...cityGroups: string[][]) {
  const allCities: string[] = [];
  cityGroups.forEach(group => allCities.push(...group));
  return `(?<cityWithProvinceCode>${allCities.join('|')})`;
}

function statusMap() {
  return [
    { status: 'available', urlSnippet: null },
    { status: 'not-available-sold', urlSnippet: 'sold-listings' },
    { status: 'not-available-sold', urlSnippet: 'sold' },
    { status: 'not-available-other', urlSnippet: 'past-listings' },
  ];
}

function buildPrimaryFilter(primaryFilter?: string) {
  if (primaryFilter) {
    if (primaryFilter === 'open-houses') {
      return { primaryFilterKey: 'openHouse', primaryFilterValue: true };
    }
  }
  return null;
}

function buildSecondaryFilter(secondaryFilter?: string) {
  if (secondaryFilter) {
    if (secondaryFilter.includes('-bedroom')) {
      return { secondaryFilterKey: 'bedrooms', secondaryFilterValue: `${secondaryFilter.split('-bedroom')[0]}+` };
    } else if (secondaryFilter.includes('-bathroom')) {
      return { secondaryFilterKey: 'bathrooms', secondaryFilterValue: `${secondaryFilter.split('-bathroom')[0]}+` };
    } else if (secondaryFilter === 'on-waterfront') {
      return { secondaryFilterKey: 'waterfront', secondaryFilterValue: true };
    } else if (secondaryFilter === 'with-garage') {
      return { secondaryFilterKey: 'garage', secondaryFilterValue: true };
    } else if (secondaryFilter === 'with-swimming-pool') {
      return { secondaryFilterKey: 'pool', secondaryFilterValue: true };
    }
  }
  return null;
}
