import { AddressType } from '@zoocasa/go-search/dist/address_type';
import { SingleAddress } from '@zoocasa/go-search/dist/single_address';
import { CountryCodeList } from 'types/countries';

/**
 * The address for Canada.
 */
export const CANADA_ADDRESS = Object.freeze(SingleAddress.fromPartial({
  addressType: AddressType.ADDRESS_TYPE_COUNTRY,
  country: CountryCodeList.CANADA,
  slug: CountryCodeList.CANADA.toLowerCase(),
  label: 'Canada',
}));

/**
 * The address for the United States.
 */
export const US_ADDRESS = Object.freeze(SingleAddress.fromPartial({
  addressType: AddressType.ADDRESS_TYPE_COUNTRY,
  country: CountryCodeList.UNITED_STATES,
  slug: CountryCodeList.UNITED_STATES.toLowerCase(),
  label: 'Usa',
}));