import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import Breadcrumbs from 'components/breadcrumbs';
import Button, { PRIMARY_THEME, TERTIARY_THEME } from 'components/control/button';
import SelectField from 'components/control/select-field';
import InternalLinks from 'components/home-page/internal-links';
import FilterIcon from 'components/icon/filter-icon';
import NotificationIcon from 'components/icon/notification-icon';
import ListingsGrid from 'components/listings-grid';
import { SurroundingCities } from 'components/related-searches';
import { headerIds, searchFilterIds, testIds } from 'constants/test-constants';
import { AVAILABLE_STATUS, Filter, Sort } from 'contexts/preferences/listing-params/types';
import { useIsSmallMobile } from 'hooks/use-size-class';
import React, { useMemo } from 'react';
import formatNumber from 'utils/format-number';
import prioritizeFeeds from 'utils/prioritize-feeds';
import { sortOptions } from 'utils/select-options';
import ProviderDisclaimer from '../../provider-disclaimer';
import styles from './style.module.scss';
import dynamic from 'next/dynamic';
import { InsightsResponse, SingleAddress } from '@zoocasa/go-search';
import AreaGuides from './components/guides/area-guides';
import { NamedContents } from 'components/named-content';
import { ListingFilterBar } from 'components/listing-filter-bar';

import type { Breadcrumb } from 'components/breadcrumbs';
import type { LinkDataType } from 'components/home-page/internal-links';
import type { ListingCardData } from 'components/listing-card';
import type { ListingParams } from 'contexts/preferences/listing-params/types';
import type SearchPrediction from 'data/search-predictions';
import type { PartialDeep } from 'type-fest';
import type { SeoLinkData } from 'data/seo-links';

const SearchFilterDeprecationWarning = dynamic(() => import('components/search-filter-deprecation-warning'), { ssr: false });
const MyLinkMyLead = dynamic(() => import('./components/my-link-my-lead'));
const MyLinkMyLeadCreate = dynamic(() => import('components/my-link-my-lead-create'));
const ProvinceCitySeoLinks = dynamic(() => import('./components/province-city-seo-links'));

//#region Types
export interface Pagination {
  page: number;
  size: number;
  count: number;
  total: number;
}

export type AreaInsightsData = {
  areaData?: InsightsResponse;
  surroundingCities?: SurroundingCities[];
}

export interface AreaListingsPageProps {
  electedAddress: SingleAddress;
  listingParams: ListingParams;
  listings: readonly ListingCardData[];
  breadcrumbs: readonly Breadcrumb[];
  pagination: Pagination;
  sort: Sort;
  heading: string;
  areaName?: string;
  areaBlurb?: string;
  areaBlurbHeading?: string;
  areaOverviewTitle?: string;
  areaNamedContent?: NamedContents;
  areaInsightsData?: AreaInsightsData;
  showMapCardExperimentView?: boolean;
  internalLinks?: LinkDataType[];
  internalLinksHeading?: string;
  seoLinks?: SeoLinkData;
  noListingsInAreaMessage?: string;
  hasNoListingsInArea?: boolean;
  expandGuidesByDefault?: boolean;
  showGuides?: boolean;
  showDisclaimer?: boolean;
  showFilterDeprecationWarning?: boolean;
  showNoImageListingsFilterWarning?: boolean;
  showsMyLinkMyLeadCreate?: boolean;
  showsMyLinkMyLead?: boolean;
  setPageNumber: (pageNumber: number) => void;
  onMobileFilterButtonClick(): void;
  onSortChanged(sort: Sort): void;
  onValueChange: (filters: PartialDeep<Filter>, changedFilter?: string) => void;
  onSaveSearchButtonClick: () => void;
  onLocationSearchClick: (searchPrediction: SearchPrediction) => void;
  onMapIconClick: () => void;
  onMoreButtonClick: () => void;
  onDeprecationWarningButtonClick?: () => void;
  onNoImageListingsFilterWarningClose?: () => void;
  trackMapCardExperimentView?: () => void;
  trackMapCardClick?: () => void;
}
//#endregion

//#region Constants
const AreaGuideClassName = styles['area-guide'];
const SearchNavClassName = styles['search-nav'];
const TitlePaddingClassName = styles['title-padding'];
const TitleSortRowClassName = styles['title-sort-row'];
const PageTitleClassName = styles['page-title'];
const PageSubtitleClassName = styles['page-subtitle'];
const SubFilterClassName = styles['sub-filter'];
const SortByClassName = styles['sort-by'];
const MobileFilterBtnClassName = styles['mobile-filter-btn'];
const GuideWrapperClassName = styles['guide-wrapper'];
const AreaBlurbClassName = styles['area-blurb'];
const AreaBlurbHeadingClassName = styles['area-blurb-heading'];
//#endregion

const AreaListingsPageView = (props: AreaListingsPageProps) => {
  const {
    electedAddress,
    listingParams,
    listings,
    breadcrumbs,
    pagination: {
      page, count, total, size,
    },
    sort,
    heading,
    areaName,
    areaBlurb,
    areaBlurbHeading,
    areaNamedContent,
    areaOverviewTitle,
    areaInsightsData,
    showMapCardExperimentView,
    internalLinks,
    internalLinksHeading,
    seoLinks,
    noListingsInAreaMessage,
    hasNoListingsInArea,
    expandGuidesByDefault,
    showGuides,
    showDisclaimer,
    showFilterDeprecationWarning = false,
    showNoImageListingsFilterWarning = false,
    showsMyLinkMyLeadCreate = false,
    showsMyLinkMyLead = false,
    setPageNumber,
    onSortChanged,
    onMobileFilterButtonClick,
    onValueChange,
    onSaveSearchButtonClick,
    onLocationSearchClick,
    onMapIconClick,
    onMoreButtonClick,
    onDeprecationWarningButtonClick = () => {},
    onNoImageListingsFilterWarningClose = () => {},
    trackMapCardExperimentView,
    trackMapCardClick,
  } = props;

  const providerIds = showDisclaimer ? prioritizeFeeds([...new Set(listings.map(l => l.providerId))]) : [];
  const isSmallMobile = useIsSmallMobile();
  const subheading = hasNoListingsInArea ? noListingsInAreaMessage : capitalizeWords(`${formatNumber(count)}+ listings found`);
  const listingsGridBreadcrumbs = [...breadcrumbs];
  const listingsGridParams = {
    page: {
      number: page,
      size: size,
    },
  };
  const listingsGridMeta = {
    totalCount: count,
    totalPages: total === 200 || total === 0 ? total : total + 1,
  };

  const sortingOptions = useMemo(() => sortOptions(listingParams.filter.status || AVAILABLE_STATUS), [listingParams.filter.status]);
  const showInternalLinks = internalLinks?.length > 0;
  const showSeoLinks = seoLinks?.links?.length > 0;

  const ProviderDisclaimerComponent = React.memo(
    ({ providerIds }: { providerIds: number[] }) => {
      return providerIds.map(p => <ProviderDisclaimer key={p} providerId={p} tenantName={'zoocasa'}/>);
    },
    (prevProps, nextProps) => {
      // Check if arrays have the same length
      if (prevProps.providerIds.length !== nextProps.providerIds.length) {
        return false;
      }
      // Check if all elements are the same
      return prevProps.providerIds.every((id, index) => id === nextProps.providerIds[index]);
    }
  );

  return (
    <div>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className={styles.component}>
        <section className={SearchNavClassName}>
          <ListingFilterBar
            onLocationSearchClick={onLocationSearchClick}
            onValueChange={onValueChange}
            onSaveSearchButtonClick={onSaveSearchButtonClick}
            onMapIconClick={onMapIconClick}
            onMoreButtonClick={onMoreButtonClick}
            filters={listingParams.filter}
            showNoImageListingsFilterWarning={showNoImageListingsFilterWarning}
            onNoImageListingsFilterWarningClose={onNoImageListingsFilterWarningClose}
          />
        </section>
        {showsMyLinkMyLead ?
          <MyLinkMyLead />
          :
          <div className={TitlePaddingClassName} />
        }
        <div className={TitleSortRowClassName}>
          <div>
            <h1 className={PageTitleClassName} data-testid={headerIds.pageHeading}>
              {heading}
            </h1>
            <h2 className={PageSubtitleClassName}>
              {subheading}
            </h2>
          </div>
          <div className={SubFilterClassName}>
            <SelectField
              className={SortByClassName}
              testId={searchFilterIds.sortByDropdown}
              ariaLabel={'Sort by dropdown'}
              value={sort}
              options={sortingOptions}
              onValueChange={onSortChanged}
            />
            <Button
              className={MobileFilterBtnClassName}
              testId={searchFilterIds.mobileFilterButton}
              theme={TERTIARY_THEME}
              Icon={FilterIcon}
              label="Filters"
              onClick={onMobileFilterButtonClick}
            />
            {showsMyLinkMyLeadCreate ?
              <MyLinkMyLeadCreate />
              :
              <Button
                theme={isSmallMobile ? PRIMARY_THEME : TERTIARY_THEME}
                label="Save Search"
                Icon={NotificationIcon}
                testId={searchFilterIds.saveSearchButtonMapPage}
                onClick={onSaveSearchButtonClick}
              />
            }
          </div>
        </div>
        <ListingsGrid
          testId={testIds.listingGrid}
          listings={listings}
          isLoading={false}
          params={listingsGridParams}
          meta={listingsGridMeta}
          showMapCardExperimentView={showMapCardExperimentView}
          breadcrumbs={listingsGridBreadcrumbs}
          setPageNumber={setPageNumber}
          listingParams={listingParams}
          trackMapCardExperimentView={trackMapCardExperimentView}
          trackMapCardClick={trackMapCardClick}
        />
        {showGuides &&
            <AreaGuides
              areaOverviewTitle={areaOverviewTitle}
              areaName={areaName}
              areaBlurbHeading={areaBlurbHeading}
              areaBlurb={areaBlurb}
              namedContent={areaNamedContent}
              isActive={expandGuidesByDefault}
            />
        }
        {showDisclaimer && <ProviderDisclaimerComponent providerIds={providerIds}/>}
        {showInternalLinks &&
        <InternalLinks isActive={expandGuidesByDefault} data={internalLinks} heading={internalLinksHeading} />
        }
        {showSeoLinks && <ProvinceCitySeoLinks seoLinks={seoLinks} />}
      </div>
      {showFilterDeprecationWarning &&
        <SearchFilterDeprecationWarning onClose={onDeprecationWarningButtonClick}/>
      }
    </div>);
};

export default AreaListingsPageView;
