import React from 'react';
import CollapsibleSection from 'components/collapsible/collapsible-section';

import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';
import useActive from 'hooks/use-active';

interface InternalLinkProps {
    index: number;
    isActive: boolean;
    title: string;
    links: JSX.Element[];
    isCollapsable?: boolean;
  }

const InternalLink = ({ index, isActive = false, title, links, isCollapsable = false }: InternalLinkProps) => {
  const [isExpanded, toggle] = useActive(isActive);
  return (isCollapsable ?
    <div key={index} className={styles['footer-group-collapsable']} data-testid={testIds.internalLink}>
      <CollapsibleSection title={title} isActive={isExpanded} onClick={toggle} isDesktop={true} className={styles['links-collapsable']}>
        <div className={styles['links-container']}>{links}</div>
      </CollapsibleSection>
    </div>
    :
    <div key={index} className={styles['footer-group-not-collapsable']} data-testid={testIds.internalLink}>
      <h2>{title}</h2>
      <div data-testid={testIds.internalLinkContent}>{links}</div>
    </div>
  );
};

export default InternalLink;
