
//#region Constants
export const AREA_OVERVIEW_NAMED_CONTENT_TYPE = 'area-overview';
export const INFRASTRUCTURE_NAMED_CONTENT_TYPE = 'infrastructure';
export const TAX_GUIDE_NAMED_CONTENT_TYPE = 'tax-guide';

const splitContentIndex = {
  [AREA_OVERVIEW_NAMED_CONTENT_TYPE]: 0,
  [INFRASTRUCTURE_NAMED_CONTENT_TYPE]: 1,
  [TAX_GUIDE_NAMED_CONTENT_TYPE]: 2,
};
//#endregion

//#region Types

export type NamedContents = string[] | null;

export type NamedContentType = typeof AREA_OVERVIEW_NAMED_CONTENT_TYPE | typeof INFRASTRUCTURE_NAMED_CONTENT_TYPE | typeof TAX_GUIDE_NAMED_CONTENT_TYPE;

type NamedContentProps = {
  contents?: NamedContents;
  type: NamedContentType;
}
//#endregion

export default function NamedContent({ contents, type }: NamedContentProps) {
  const html = getNamedContentByType(contents, type);

  return (
    <div className='named-content'>
      {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
    </div>
  );
}

export function getNamedContentByType(namedContent: NamedContents, type: NamedContentType) {
  return namedContent?.[splitContentIndex[type]];
}

export function hasNamedContentWithType(namedContent: NamedContents, type: NamedContentType) {
  return namedContent?.[splitContentIndex[type]]?.trim().length > 0;
}
