import { HeadDataType } from 'components/head-data';
import { Breadcrumb } from 'components/breadcrumbs';
import {
  capitalizeSentences,
  capitalizeWords,
} from '@zoocasa/node-kit/strings/capitalize';
import {
  getTargetedUrlPropertyTypeSelection,
  HOMES_TARGETED_URL_PROPERTY_TYPE,
} from 'utils/listing-query-helper';
import {
  LocationData,
  LocationInfo,
  ListingFilters,
  PropertyInsights,
} from './types';
import { CountryCodeList } from 'types/countries';
import { HeadTagsBuilder } from './head-tags-builder';


export type HrefLangLink = {
  hrefLang: string;
  href: string;
}
/**
 * Parameters required to build head tags
 */
export type BuildHeadTagsParams = {
    /** The canonical URL for the page */
    canonicalUrl: string;
    /** Location information */
    location: LocationData;
    /** Applied listing filters */
    filters: ListingFilters;
    /** Property insights data */
    propertyInsights: PropertyInsights;
    /** Breadcrumb navigation items */
    breadcrumbs?: Breadcrumb[];
    /** Lowest property price in the results */
    lowestPrice: number | null;
    /** Highest property price in the results */
    highestPrice: number | null;
    /** Website name */
    siteName: string;
    /** The hreflang languages for the page */
    hrefLangs: HrefLangLink[];
  }

function getLocationInfo(location: LocationData): LocationInfo {
  const { administrativeArea, city, areaName } = location;
  const place = capitalizeSentences(areaName);
  const cityAndProvince = !administrativeArea && !city
    ? areaName
    : city
      ? `${place}, ${capitalizeWords(administrativeArea || '')}`
      : capitalizeWords(administrativeArea || '');

  return { place, cityAndProvince };
}

/**
 * Builds complete head tags data for a real estate listings page.
 *
 * @param params Configuration parameters for building head tags
 *
 * @returns Complete head data object containing SEO metadata and schema markup
 *
 * @example
 * ```typescript
 * const headTags = buildHeadTags({
 *   canonicalUrl: "https://www.zoocasa.com/toronto-on-real-estate",
 *   location: {
 *     areaName: "Toronto",
 *     city: "Toronto",
 *     administrativeArea: "ON",
 *     country: "CA"
 *   },
 *   filters: {
 *     homeType: "Houses",
 *     rental: false,
 *     status: true
 *   },
 *   propertyInsights: {
 *     totalListings: 1234,
 *     averagePrices: {
 *       total: 850000,
 *       house: 1200000,
 *       condo: 600000
 *     },
 *     counts: {
 *       house: 500,
 *       condo: 734
 *     }
 *   },
 *   breadcrumbs: [
 *     { name: "ON", href: "/ontario-real-estate" },
 *     { name: "Toronto", href: "/toronto-on-real-estate" }
 *   ],
 *   lowestPrice: 500000,
 *   highestPrice: 2000000,
 *   siteName: "Zoocasa"
 * });
 *
 * // Returns:
 * {
 *   canonicalUrl: "https://www.example.com/toronto-real-estate",
 *   title: "Toronto Real Estate | Example Site",
 *   metaDescription: "Browse 1,234 homes for sale in Toronto. View listing photos, property details, and prices.",
 *   schema: [
 *     // BreadcrumbList schema
 *     {
 *       "@type": "BreadcrumbList",
 *       "itemListElement": [
 *         {
 *           "@type": "ListItem",
 *           "position": 1,
 *           "name": "ON",
 *           "item": "https://www.zoocasa.com/ontario-real-estate"
 *         },
 *         {
 *           "@type": "ListItem",
 *           "position": 2,
 *           "name": "Toronto",
 *           "item": "https://www.zoocasa.com/toronto-on-real-estate"
 *         }
 *       ]
 *     },
 *     // Product schema
 *     {
 *       "@type": "Product",
 *       "name": "Toronto Real Estate",
 *       "offers": {
 *         "@type": "AggregateOffer",
 *         "priceCurrency": "CAD",
 *         "lowPrice": 500000,
 *         "highPrice": 2000000
 *       }
 *     },
 *     // FAQ Page schema
 *     {
 *       "@type": "FAQPage",
 *       "mainEntity": [
 *         {
 *           "@type": "Question",
 *           "name": "How many homes are there for sale in Toronto?",
 *           "acceptedAnswer": {
 *             "@type": "Answer",
 *             "text": "There are a total of 1,234 homes for sale in Toronto, Ontario."
 *           }
 *         },
 *         {
 *           "@type": "Question",
 *           "name": "What is the average listing price of a home for sale in Toronto?",
 *           "acceptedAnswer": {
 *             "@type": "Answer",
 *             "text": "The average listing price of all home types in Toronto, Ontario is $850,000."
 *           }
 *         }
 *       ]
 *     }
 *   ]
 * }
 * ```
 */
export function buildHeadTags(params: BuildHeadTagsParams): HeadDataType {
  const builder = new HeadTagsBuilder()
    .setCanonicalUrl(params.canonicalUrl)
    .setTitle(params.siteName, params.location.areaName, params.filters)
    .setMetaDescription(params.location.areaName, params.filters, params.propertyInsights?.totalListings)
    .setHrefLangs(params.hrefLangs);

  if (params.breadcrumbs) {
    builder.setBreadcrumbList(params.breadcrumbs);
  }

  const homeTypeSelection = getTargetedUrlPropertyTypeSelection(params.filters.homeType);
  const homeTypeValue = homeTypeSelection !== HOMES_TARGETED_URL_PROPERTY_TYPE ? ` & ${homeTypeSelection}` : '';
  const currency = params.location.country === CountryCodeList.UNITED_STATES ? 'USD' : 'CAD';
  const productName = `${params.location.areaName} Real Estate${homeTypeValue}`;

  builder.setProduct(productName, currency, params.highestPrice, params.lowestPrice);

  const locationInfo = getLocationInfo(params.location);
  if (params.propertyInsights && params.propertyInsights.totalListings) {
    builder.setNumberOfHomesQuestion(
      homeTypeSelection,
      params.filters.rental,
      locationInfo,
      params.propertyInsights.totalListings
    );
  }

  if (homeTypeSelection === HOMES_TARGETED_URL_PROPERTY_TYPE) {
    if (params.propertyInsights) {
      builder.setAveragePriceQuestion(
        params.propertyInsights,
        params.filters.rental,
        locationInfo);
    }
  } else {
    const propertyType = homeTypeSelection.toLowerCase() as 'house' | 'townhouse' | 'condo';
    if (params.propertyInsights) {
      builder.setPropertyTypeQuestion(
        propertyType,
        params.propertyInsights,
        locationInfo,
        params.filters.rental
      );
    }
  }

  return builder.build();
}