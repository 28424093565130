import React, { ReactNode, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { buildClassName } from 'utils/build-class-name';
import { loadStylesheet } from 'utils/load-file';
import styles from './style.module.scss';
import { useIsMobile } from 'hooks/use-size-class';

export interface CollapsibleProps {
  title: string;
  isActive: boolean;
  onClick(): void;
  className?: string;
  headerClassName?: string;
  detailsClassName?: string;
  children: ReactNode;
  testId?: string;
  isDesktop?: boolean;
  isHoverable?: boolean;
  disableGutters?: boolean, 
  iconOverride?: {
    expandedIcon: ReactNode;
    collapsedIcon: ReactNode;
  }
}

const setExpandIcon = (isDesktop: boolean, isActive?: boolean, iconOverride?: { expandedIcon: ReactNode, collapsedIcon: ReactNode }) => {
  if (iconOverride) {
    return isActive ? iconOverride.expandedIcon : iconOverride.collapsedIcon;
  }
  return isDesktop ? <KeyboardArrowDownIcon /> :<ArrowDropDown className={styles.arrow} />;
};

export default function CollapsibleSection({ title, isActive, onClick, className, children, testId, isDesktop, isHoverable, disableGutters = false, iconOverride, headerClassName, detailsClassName }: CollapsibleProps) {
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    const source = 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined';
    loadStylesheet(source, document.querySelector('body') as any);
  }, []);

  const handleChange = (event: React.SyntheticEvent) => {
    if (event.type === 'click' || event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter') {
      onClick();
    }
  };
    
  const isExpanded = isHoverable ? isOpen || isActive : isActive;

  return (
    <Accordion 
      expanded={isActive ? true : isOpen}
      onPointerEnter={e => { if (isHoverable && e.pointerType === 'mouse') setIsOpen(true);}} 
      onPointerLeave={e => { if (isHoverable && e.pointerType === 'mouse') setIsOpen(false);}} 
      onChange={handleChange}
      className={buildClassName(styles.component, className)} 
      disableGutters={disableGutters} 
      data-active={isActive}>
      <AccordionSummary
        expandIcon={setExpandIcon(isDesktop, isActive, iconOverride)}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={buildClassName(styles.header, headerClassName)}
        data-testid={testId}
      >
        <h3 className={styles.label}>{title}</h3>
      </AccordionSummary>
      <AccordionDetails className={detailsClassName}>
        <div className={styles.content} data-testid={`${testId}Expanded`}>
          {isExpanded&& children}
          <div className={styles['text-divider']}></div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
