import ArrowIcon from 'components/icon/arrow-icon';
import { testIds } from 'constants/test-constants';
import styles from './style.module.scss';
import { Breadcrumb, BreadcrumbType } from './types';
import { capitalizeWords } from '@zoocasa/node-kit/strings';

export type BreadcrumbSegmentProps = {
  /** The breadcrumb segment to display. */
  breadcrumb: Breadcrumb
  /** Whether to show the arrow icon. */
  showArrow: boolean
}

/**
 * A single breadcrumb segment in a breadcrumb trail.
 *
 * NOTE: The `data-testid` for this component is generated from the breadcrumb name
 * by removing all non-alphanumeric characters and converting to lowercase, and appending
 * the string '.breadcrumbLink' to the end.
 */
export function BreadcrumbSegment({ breadcrumb, showArrow }: BreadcrumbSegmentProps) {
  const testId = `${breadcrumb.name.replace(/[.,-\s]/g, '') + testIds.breadcrumbLink}`.toLowerCase();
  const isCityLevel = breadcrumb.type !== BreadcrumbType.BREADCRUMB_TYPE_COUNTRY && breadcrumb.type !== BreadcrumbType.BREADCRUMB_TYPE_PROVINCE_OR_STATE;
  const title = isCityLevel ? capitalizeWords(breadcrumb.name) : breadcrumb.name;
  const link = breadcrumb.link || '';
  const className = 'tertiary';

  return (
    <span className={styles.item}>
      <a href={link} title={title} className={className} data-testid={testId}>
        <span>{title}</span>
      </a>
      {showArrow && <ArrowIcon className={styles.arrow} />}
    </span>
  );
}
