import React from 'react';
import InternalLink from './InternalLink';
import MobileInternalLink from './mobileInternalLink';
import { useIsMobile } from 'hooks/use-size-class';
import Link from 'components/link';
import { testIds } from 'constants/test-constants';

import styles from './style.module.scss';

export type LinkDataType = {
  title: string;
  links: {
    label: string;
    link: string;
  }[];
};

export type InternalLinksContainerProps = {
  isActive?: boolean;
  data: LinkDataType[];
  heading?: string;
  showGroupSeparators?: boolean;
  isCollapsable?: boolean;
}

const ColumnSeparatorPrefix = 'ColumnSeparator';
const LayoutContainerWithSeparators = styles['layout-container-with-separators'];
const LayoutContainerWithoutSeparators = styles['layout-container-without-separators'];

const MobileHeadingSeparator = React.memo(() => (<div key="mobile-separator" className={styles['heading-border']}></div>));
const RowSeparator = React.memo(() => (<div key="row-separator" className={styles['row-border']}></div>));
const DesktopHeadingSeparator = React.memo(() => (<div key="desktop-separator" className={styles['row-border']}></div>));
const ColumnSeparator = React.memo(() => <div className={styles['column-separator']}><br /></div>);

const Groups = React.memo(({ data, isActive, isMobile, showColumnSeparators, isCollapsable }: { data: LinkDataType[], isActive: boolean, isMobile: boolean, showColumnSeparators: boolean, isCollapsable: boolean }) => {
  let groups = data?.map(group => {
    const links = group.links.map(link => (
      <Link key={`${group.title}-${link.label}-${link.link}`} href={link.link}>{link.label}</Link>
    ));
    if (links.length === 0) return;
    return (isMobile
      ? <MobileInternalLink key={`mobile-${group.title}`} index={data.indexOf(group)} isActive={isActive} title={group.title} links={links} />
      : <InternalLink key={`desktop-${group.title}`} index={data.indexOf(group)} isActive={isActive} title={group.title} links={links} isCollapsable={isCollapsable} />);
  }).filter(Boolean);

  if (!isMobile && !showColumnSeparators) {
    // if the list is 8 lists long, split like this
    groups.splice(0, 0, <DesktopHeadingSeparator key="desktop-heading-separator" />);
    if (groups.length > 4) {
      groups.splice(5, 0, <RowSeparator key="row-separator" />);
    }
  }
  if (isMobile) {
    groups?.splice(0, 0, <MobileHeadingSeparator key="mobile-heading-separator" />);
  } else if (showColumnSeparators) {
    // Insert the separators between each dropdown
    groups = groups?.flatMap((value, index, array) =>
      array.length -1 !== index
        ? [value, <ColumnSeparator key={`${ColumnSeparatorPrefix}-${index}`} />]
        : value
    );
    groups?.splice(0, 0, <ColumnSeparator key={`${ColumnSeparatorPrefix}-start`} />);
    groups?.splice(groups.length, 0, <ColumnSeparator key={`${ColumnSeparatorPrefix}-end`} />);
  }
  return groups;
}, (prevProps, nextProps) => {
  const isDataEqual = prevProps.data.length === nextProps.data.length &&
    prevProps.data.every((group, index) => {
      const nextGroup = nextProps.data[index];
      return group.title === nextGroup.title &&
        group.links.length === nextGroup.links.length &&
        group.links.every((link, linkIndex) =>
          link.label === nextGroup.links[linkIndex].label &&
          link.link === nextGroup.links[linkIndex].link
        );
    });

  return isDataEqual &&
         prevProps.isActive === nextProps.isActive &&
         prevProps.isMobile === nextProps.isMobile &&
         prevProps.showColumnSeparators === nextProps.showColumnSeparators &&
         prevProps.isCollapsable === nextProps.isCollapsable;
});

export default function InternalLinksContainer({ isActive = false, data, heading = '', showGroupSeparators: showColumnSeparators = false, isCollapsable = false }: InternalLinksContainerProps) {
  if (data?.length === 0) {
    return null;
  }

  const isMobile = useIsMobile();
  const showHeading = heading.trim().length > 0;
  const className = showColumnSeparators && !isMobile ? LayoutContainerWithSeparators : LayoutContainerWithoutSeparators;

  return (
    <div className={styles.component} data-testid={testIds.internalLinksContainer} >
      {showHeading && <h3 className={styles.heading}>{heading}</h3>}
      <div className={className}>
        <Groups data={data} isActive={isActive} isMobile={isMobile} showColumnSeparators={showColumnSeparators} isCollapsable={isCollapsable} />
      </div>
    </div>
  );
}
