import { usePreferencesContext, useUserContext, useThemeContext } from 'contexts';
import { handleSearchResult } from 'components/search-bar/useSearchBar';

import type SearchPrediction from 'data/search-predictions';
import type { ThemeNames } from 'types/themes';

/**
 * Hook to handle search result clicks across the application
 *
 * @returns A callback function that handles search result clicks
 */
export const useSearchResultClick = () => {
  const { listingParams, addToRecentSearches } = usePreferencesContext();
  const { user } = useUserContext();
  const { themeName } = useThemeContext();

  return (searchPrediction: SearchPrediction) => {
    handleSearchResult({
      searchPrediction,
      listingParams,
      user,
      addToRecentSearches,
      themeName: themeName as ThemeNames,
    });
  };
};