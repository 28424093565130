import { AddressType, SingleAddress } from '@zoocasa/go-search';
import Address, { AddressHierarchyPath, NEIGHBOURHOOD_ADDRESS_TYPE, PROVINCE_ADDRESS_TYPE, SUB_DIVISION_ADDRESS_TYPE } from 'data/addresses';
import { CountryCode, CountryCodeList } from 'types/countries';
import { provinceOrStateCodeFromName, ProvinceOrState } from 'utils/province_or_state';
import sortAddresses from 'utils/sort-addresses';
import { Breadcrumb, BreadcrumbType } from './types';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';

//#region Deprecated breadcrumbs functions
/** @deprecated use {@link createBreadcrumbs} instead */
export const generateBreadcrumbs = (country: CountryCode, addresses: Address[], addressPath?: string): Breadcrumb[] => {
  const isAmericanLocation = country === CountryCodeList.UNITED_STATES;
  const countryBreadcrumb: Breadcrumb = { 
    type: BreadcrumbType.BREADCRUMB_TYPE_COUNTRY, 
    name: isAmericanLocation ? 'USA' : 'CA', 
    link: `/${isAmericanLocation ? 'us' : 'ca'}-real-estate`, 
  };

  const sortedAddresses = sortAddresses(addresses);
  const breadcrumbs: Breadcrumb[] = sortedAddresses.map(mapAddressToBreadcrumb);
  return [countryBreadcrumb, ...breadcrumbs];
};

function mapAddressToBreadcrumb({ label, generateLink, addressType }: Address): Breadcrumb {
  let type: BreadcrumbType = BreadcrumbType.BREADCRUMB_TYPE_UNKNOWN;
  let name: string = capitalizeWords(label.toLowerCase());
  const link: string = generateLink();

  if (addressType === PROVINCE_ADDRESS_TYPE) {
    type = BreadcrumbType.BREADCRUMB_TYPE_PROVINCE_OR_STATE;
    name = provinceOrStateCodeFromName(label as ProvinceOrState)?.toUpperCase();
  } else if (addressType === SUB_DIVISION_ADDRESS_TYPE) {
    type = BreadcrumbType.BREADCRUMB_TYPE_SUB_DIVISION;
  } else if (addressType === NEIGHBOURHOOD_ADDRESS_TYPE) {
    type = BreadcrumbType.BREADCRUMB_TYPE_NEIGHBOURHOOD;
  }
  const breadcrumb: Breadcrumb = { type, name, link };
  return breadcrumb;
}
//#endregion

//#region Breadcrumbs factory functions

const HAS_AREA_PAGE_ADDRESS_TYPES = [
  AddressType.ADDRESS_TYPE_COUNTRY, 
  AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE, 
  AddressType.ADDRESS_TYPE_SUB_DIVISION, 
  AddressType.ADDRESS_TYPE_NEIGHBOURHOOD,
];

const SUPPORTED_BREADCRUMB_ADDRESS_TYPES = [
  AddressType.ADDRESS_TYPE_COUNTRY, 
  AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE, 
  AddressType.ADDRESS_TYPE_SUB_DIVISION, 
  AddressType.ADDRESS_TYPE_NEIGHBOURHOOD, 
  AddressType.ADDRESS_TYPE_ADDRESS,
];

/**
 * Creates breadcrumbs from a list of addresses.
 *
 * @param address An address hierarchy path object containing a list of addresses to create breadcrumbs from. The addresses 
 *  are expected to be sorted hierarchically as follows: Country, Province, City, Neighborhood, Multi-Unit and address.
 * @param addressPath The path to the address.
 * @returns The breadcrumbs for the given addresses. If the addresses are not valid, the function returns undefined.
 */
export const createBreadcrumbs = (address: AddressHierarchyPath): readonly Breadcrumb[] | undefined => {
  if (!address) {
    return undefined;
  }
  const supportedAddresses = address.segments.filter(supportedAddressesPredicate);
  const breadcrumbs: readonly Breadcrumb[] = supportedAddresses.map(segment => {
    const { label, addressType } = segment;
    const breadcrumb: Breadcrumb = {
      name: '',
      link: '',
      type: BreadcrumbType.BREADCRUMB_TYPE_UNKNOWN,
    };
    const isCountry = addressType === AddressType.ADDRESS_TYPE_COUNTRY;
    const isProvinceOrState = addressType === AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE;
    const isSubDivision = addressType === AddressType.ADDRESS_TYPE_SUB_DIVISION;
    const isNeighbourhood = addressType === AddressType.ADDRESS_TYPE_NEIGHBOURHOOD;
    const isAddress = addressType === AddressType.ADDRESS_TYPE_ADDRESS;
  
    if (isCountry) {
      const { countryCode, country } = segment;
      if (countryCode?.toUpperCase() === CountryCodeList.UNITED_STATES) {
        breadcrumb.name = country;
      } else {
        breadcrumb.name = countryCode?.toUpperCase();
      }
      breadcrumb.link = address.pathSegments[AddressType.ADDRESS_TYPE_COUNTRY];
      breadcrumb.type = BreadcrumbType.BREADCRUMB_TYPE_COUNTRY;
    } else if (isProvinceOrState) {
      const { provinceOrState } = segment;
      breadcrumb.name = provinceOrState;
      breadcrumb.link = address.pathSegments[AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE];
      breadcrumb.type = BreadcrumbType.BREADCRUMB_TYPE_PROVINCE_OR_STATE;
    } else if (isSubDivision) {
      breadcrumb.name = label;
      breadcrumb.link = address.pathSegments[AddressType.ADDRESS_TYPE_SUB_DIVISION];
      breadcrumb.type = BreadcrumbType.BREADCRUMB_TYPE_SUB_DIVISION;
    } else if (isNeighbourhood) {
      breadcrumb.name = label;
      breadcrumb.link = `${address.pathSegments[AddressType.ADDRESS_TYPE_SUB_DIVISION]}${address.pathSegments[AddressType.ADDRESS_TYPE_NEIGHBOURHOOD]}`;
      breadcrumb.type = BreadcrumbType.BREADCRUMB_TYPE_NEIGHBOURHOOD;
    } else if (isAddress) {
      breadcrumb.name = label;
      breadcrumb.link = `${address.pathSegments[AddressType.ADDRESS_TYPE_SUB_DIVISION]}${address.pathSegments[AddressType.ADDRESS_TYPE_ADDRESS]}`;
      breadcrumb.type = BreadcrumbType.BREADCRUMB_TYPE_ADDRESS;
    }
  
    return breadcrumb;
  });
  return breadcrumbs;
};

function supportedAddressesPredicate(segment: SingleAddress): boolean {
  return SUPPORTED_BREADCRUMB_ADDRESS_TYPES.includes(segment.addressType);
}
//#endregion