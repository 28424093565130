import replaceFrenchAccents from 'utils/replace-french-accents';
import { SortBy, TopCitiesResponse } from '@zoocasa/go-search';
import { countryCodeFromProvinceOrState, ProvinceAndState } from 'utils/province_or_state';
import { cleanDashedString } from 'data/addresses';
import { capitalizeWords } from '@zoocasa/node-kit/strings';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { SearchApi, SearchApiType } from 'data/search/api';
import { getGoSearchHost, getInsightsHost, isServerSide } from 'utils/host-config';
import { cloneDeep } from 'lodash';
import deepmerge from 'deepmerge';
import { DEFAULT_HAS_IMAGE_VALUE } from 'contexts/preferences/listing-params';
import { SearchByAreaFilterType } from 'data/search/area/types';
import { ThemeName } from 'themes';
import { getSurroundingCities } from 'utils/related-searches';

import type { LinkDataType } from 'components/home-page/internal-links';

export interface NearbyCity {
  city: string;
  province: string;
  slug: string;
}
export interface NearbyNeighbourhood {
  neighbourhood: string;
  city: string;
  province: string;
  slug: string;
}


const RECENT_LISTINGS_IDX = 0;
const PROPERTY_TYPES_IDX = 1;
const NEARBY_NEIGHBOURHOODS_IDX = 2;
export const NEARBY_CITIES_IDX = 3;
const HOUSES_FOR_SALE_IDX = 4;
const CONDOS_FOR_SALE_IDX = 5;
const HOMES_FOR_RENT_IDX = 6;
const CONDOS_FOR_RENT_IDX = 7;

function createLinkData(title: string):LinkDataType {
  return {
    title,
    links: [],
  };
}

function createFooterDataTemplate(area = ''): LinkDataType[] {
  const footerDataTemplate: LinkDataType[] = new Array(7);
  const hasAreaStr = area.trim().length > 0;
  const prefix = hasAreaStr ? `${area} ` : '';
  footerDataTemplate[RECENT_LISTINGS_IDX] = createLinkData(`${prefix}Latest Listings`);
  footerDataTemplate[PROPERTY_TYPES_IDX] = createLinkData(`${hasAreaStr ? prefix : 'Check by'} Property Types`);
  footerDataTemplate[NEARBY_NEIGHBOURHOODS_IDX] = createLinkData(`${prefix}Neighbourhoods`);
  footerDataTemplate[NEARBY_CITIES_IDX] = createLinkData(`${hasAreaStr ? 'Popular ' : ''}Nearby Cities`);
  footerDataTemplate[HOUSES_FOR_SALE_IDX] = createLinkData(`Houses for Sale near ${area}`);
  footerDataTemplate[CONDOS_FOR_SALE_IDX] = createLinkData(`Condos for Sale near ${area}`);
  footerDataTemplate[HOMES_FOR_RENT_IDX] = createLinkData(`For Rent near ${area}`);

  return footerDataTemplate;
}

function createPropertyTypesLinkData(location: {area: string; slug: string}) {
  const formattedSlug = replaceFrenchAccents(location.slug);
  const propertyTypesLinks = [
    {
      label: `Houses for Sale ${location.area}`,
      link: `/${formattedSlug}-real-estate/houses`,
    }, {
      label: `Condos for Sale ${location.area}`,
      link: `/${formattedSlug}-real-estate/condos`,
    }, {
      label: `Townhouses for Sale ${location.area}`,
      link: `/${formattedSlug}-real-estate/townhouses`,
    }, {
      label: `For Rent near ${location.area}`,
      link: `/${formattedSlug}-real-estate/for-rent`,
    },
  ];
  return propertyTypesLinks;
}

async function getRecentListings(province: string, city: string, neighbourhood: string, searchApi: SearchApiType, tenant: ThemeName) {
  const country = countryCodeFromProvinceOrState(province);
  const filter: SearchByAreaFilterType = cloneDeep(deepmerge(defaultListingParams.filter, { hasImage: DEFAULT_HAS_IMAGE_VALUE, homeType: { house: true, condo: true, townhouse: true, land: true, commercial: true, farm: true }, tenant }));
  const listings = await searchApi.searchByArea(country, province, city, neighbourhood, filter, tenant, SortBy.DateDesc, 0, 10);

  const recentListings = listings.data
    .map(listing => {
      const { streetName, streetNumber, addressUrlAbsolutePath, listingUrlAbsolutePath } = listing;
      const label = streetNumber ? `${streetNumber} ${streetName}` : streetName;
      return {
        label: capitalizeWords(label.toLowerCase()),
        link: cleanDashedString(addressUrlAbsolutePath || listingUrlAbsolutePath),
      };
    });
  return recentListings;
}

export async function getPopularNeighbourhoods(city: string, city_slug: string, province: string) {
  const country = countryCodeFromProvinceOrState(province);
  let resp;
  const popularNearbyNeighbourhoodsUrl = `${getInsightsHost(isServerSide())}/insights/popular/${country}/${province}/${city}?limit=10`;
  try {
    const response = await fetchWithRetry(popularNearbyNeighbourhoodsUrl, { method: 'GET' });

    if (!response.ok) {
      console.error('Failed to fetch popular nearby neighbourhoods: %d %s', response.status, response.statusText);
      return [];
    }
    const content = await response.blob();
    const buffer = await content.arrayBuffer();
    const topCities = TopCitiesResponse.decode(new Uint8Array(buffer)).topCities;
    resp = await Promise.all(topCities.slice(0, 10).map(async c => {
      return {
        label: c.Name,
        link: `/${city_slug}-real-estate/${c.Slug.replace(`-${city_slug}`, '')}`,
      };
    }));
    return resp;
  } catch (error: any) {
    console.error('Failed to fetch popular nearby neighbourhoods: %s', error);
    return [];
  }
}

async function getNearbyNeighbourhoods(city_slug: string, latitude: number, longitude: number) {
  if (latitude && longitude) {
    let resp;
    const nearbyNeighbourhoodsUrl = `${getInsightsHost(isServerSide())}/insights/nearby?latitude=${latitude}&longitude=${longitude}`;
    try {
      const response = await fetchWithRetry(nearbyNeighbourhoodsUrl, { method: 'GET' });

      if (!response.ok) {
        console.error('Failed to fetch nearby neighbourhoods: %d %s', response.status, response.statusText);
        return [];
      }
      const content = await response.blob();
      const buffer = await content.arrayBuffer();
      const topCities = TopCitiesResponse.decode(new Uint8Array(buffer)).topCities;
      resp = await Promise.all(topCities.slice(0, 10).map(async c => {
        return {
          label: c.Name,
          link: `${city_slug}-real-estate/${c.Slug.replace(`-${city_slug}`, '')}`,
        };
      }));
      return resp;
    } catch (error: any) {
      console.error('Failed to fetch popular nearby cities: %s', error);
      return [];
    }
  }
  return [];
}

export default async function generateCityFooterData(city: string, city_slug: string, tenant, province: keyof typeof ProvinceAndState, limit = 10, useLegacySearchFilter = true, isSold: boolean): Promise<LinkDataType[]>{
  // 1) Build the default template
  const nearbyCityMap = await getSurroundingCities(ProvinceAndState[province]) || [];
  const footerDataTemplate: LinkDataType[] = createFooterDataTemplate(city);
  const searchApi = SearchApi.create(getGoSearchHost(isServerSide()), useLegacySearchFilter);

  // 2) Populate the usual links (only used if isSold is false, or partially used if true)
  footerDataTemplate[PROPERTY_TYPES_IDX].links = createPropertyTypesLinkData({ area: city, slug: city_slug });
  footerDataTemplate[RECENT_LISTINGS_IDX].links = await getRecentListings(province, city, '', searchApi, tenant);

  // 3) Fill in data for "Houses for Sale," "For Rent," etc.
  nearbyCityMap.slice(0, limit).forEach((currentValue: NearbyCity) => {
    const formattedSlug = replaceFrenchAccents(currentValue.slug);
    const formattedCityName = capitalizeWords(currentValue.city);

    // Houses for Sale
    footerDataTemplate[HOUSES_FOR_SALE_IDX].links.push({
      label: `${formattedCityName} Houses for Sale`,
      // SEO: internal links with houses as anchor text should link to the base level area pages instead of the houses subvariant
      link: `/${formattedSlug}-real-estate`,
    });

    // For Rent near ...
    footerDataTemplate[HOMES_FOR_RENT_IDX].links.push({
      label: `${formattedCityName} Houses for Rent`,
      link: `/${formattedSlug}-real-estate/for-rent`,
    });

    // Condos for Sale
    footerDataTemplate[CONDOS_FOR_SALE_IDX].links.push({
      label: `${formattedCityName} Condos For Sale`,
      link: `/${formattedSlug}-real-estate/condos`,
    });

    // "Popular Nearby Cities" (Homes for Sale)
    footerDataTemplate[NEARBY_CITIES_IDX].links.push({
      label: `${formattedCityName} Homes for Sale`,
      link: `/${formattedSlug}-real-estate`,
    });
  });

  // 4) Add popular neighbourhoods (only if not sold)
  const popularNeighbourhoods = await getPopularNeighbourhoods(city, city_slug, province);
  if (!isSold) {
    // Only keep the "Nearby Neighbourhoods" section in the normal (non-sold) scenario
    if (popularNeighbourhoods?.length > 0) {
      footerDataTemplate[NEARBY_NEIGHBOURHOODS_IDX].links = popularNeighbourhoods.map((item: { label: string; link: string; }) => {
        return {
          ...item,
          label: capitalizeWords(item.label),
        };
      });
    } else {
      footerDataTemplate.splice(NEARBY_NEIGHBOURHOODS_IDX, 1);
    }
  }

  // 5) If isSold is false, return the full standard data
  if (!isSold) {
    return footerDataTemplate;
  }

  // ------------------------------------------------------------------
  // If isSold = true, we override the standard sections and build
  // the final data array with only:
  //   a) "Active Listing in Nearby Cities" (replace “Popular Nearby Cities”)
  //   b) "Active Rentals near [city]"      (replace “For Rent near [city]”)
  //   c) NEW "Recently sold near [city]"
  // ------------------------------------------------------------------

  // 5a) Rename the two sections we want to keep
  footerDataTemplate[NEARBY_CITIES_IDX].title = 'Active Listing in Nearby Cities';
  footerDataTemplate[HOMES_FOR_RENT_IDX].title = `Active Rentals near ${city}`;

  // 5b) Keep only those two
  const finalFooterData: LinkDataType[] = [
    footerDataTemplate[NEARBY_CITIES_IDX], // index 3
    footerDataTemplate[HOMES_FOR_RENT_IDX], // index 6
  ];

  // 5c) Create a NEW "Recently sold near [city]" section
  const recentlySoldSection = createLinkData(`Recently sold near ${city}`);

  nearbyCityMap.slice(0, 10).forEach((currentValue: NearbyCity) => {
    const formattedSlug = replaceFrenchAccents(currentValue.slug);
    const formattedCityName = capitalizeWords(currentValue.city);

    recentlySoldSection.links.push({
      label: `Recently Sold Homes in ${formattedCityName}`,
      link: `/${formattedSlug}-real-estate/sold`,
    });
  });

  finalFooterData.push(recentlySoldSection);

  return finalFooterData;
}

export async function generateNeighbourhoodFooterData(city: string, city_slug: string, neighbourhood: string, neighbourhood_slug: string, province: keyof typeof ProvinceAndState, latitude: number, longitude: number, tenant: ThemeName, useLegacySearchFilter = true): Promise<LinkDataType[]>{
  const nearbyCityMap = await getSurroundingCities(ProvinceAndState[province]) || [];
  const footerDataTemplate: LinkDataType[] = createFooterDataTemplate(city);
  const searchApi = SearchApi.create(getGoSearchHost(isServerSide()), useLegacySearchFilter);
  footerDataTemplate[PROPERTY_TYPES_IDX].links = createPropertyTypesLinkData({ area: neighbourhood, slug: neighbourhood_slug });
  footerDataTemplate[RECENT_LISTINGS_IDX].links = await getRecentListings(province, city, neighbourhood, searchApi, tenant);

  const footerData = nearbyCityMap.reduce((previousValue: LinkDataType[], currentValue: NearbyCity)=> {
    const formattedCityName = capitalizeWords(currentValue.city);
    previousValue?.[HOUSES_FOR_SALE_IDX]?.links.push({
      label: `${formattedCityName} Houses for Sale`,
      link: `/${currentValue.slug}-real-estate/houses`,
    });
    previousValue?.[HOMES_FOR_RENT_IDX]?.links.push({
      label: `${formattedCityName} Houses for Rent`,
      link: `/${currentValue.slug}-real-estate/filter?rental=true&townhouse=false&condo=false`,
    });
    previousValue?.[CONDOS_FOR_SALE_IDX]?.links.push({
      label: `${formattedCityName} Condos For Sale`,
      link: `/${currentValue.slug}-real-estate/condos`,
    });
    previousValue?.[CONDOS_FOR_RENT_IDX]?.links.push({
      label: `${formattedCityName} Condos For Rent`,
      link: `/${currentValue.slug}-real-estate/for-rent`,
    });
    previousValue?.[NEARBY_CITIES_IDX]?.links.push({
      label: `${formattedCityName} Homes for Sale`,
      link: `/${currentValue.slug}-real-estate`,
    });
    return previousValue;
  }, footerDataTemplate);

  // TODO: replace with go-search func & remove service
  const nearbyNeighbourhood = await getNearbyNeighbourhoods(city_slug, latitude, longitude);
  if (nearbyNeighbourhood?.length > 0) {
    footerDataTemplate[NEARBY_NEIGHBOURHOODS_IDX].links = nearbyNeighbourhood;
  } else {
    footerDataTemplate.splice(NEARBY_NEIGHBOURHOODS_IDX, 1);
  }

  return footerData;
}