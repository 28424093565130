export { type EmberPageServerSidePropsType, getServerSideProps } from './ember-page';
import EmberPage from './ember-page';
export { EmberPage };

export { type Pagination } from './area-listings-page';
export { type AreaListingsPageServerSideProps as DeprecatedAreaListingsPageServerSideProps } from './area-listings-page/get-server-side-props';
import getDeprecatedAreaListingsPageServerSideProps from './area-listings-page/get-server-side-props';
export { getDeprecatedAreaListingsPageServerSideProps };
import DeprecatedAreaListingsPage from './area-listings-page';
export { DeprecatedAreaListingsPage };


import AreaListingsPage from 'components/dynamic-page/new-area-listings-page/area_listings_page_view_controller';
export { AreaListingsPage };
import getServerSideProps from 'components/dynamic-page/new-area-listings-page/get-server-side-props';
export { getServerSideProps as getAreaListingsPageServerSideProps };
export { type AreaListingsPageServerSideProps } from 'components/dynamic-page/new-area-listings-page/get-server-side-props';
export { type AreaListingsPageViewModel } from 'components/dynamic-page/new-area-listings-page/area_listings_page_view_model';

export {
  AREA_LISTINGS_ROUTE,
  EMBER_ROUTE,
  NOT_FOUND_404_ROUTE,
} from './route-matchers';

export { generateRouteMatchObjectFromPath as generateOldRouteMatchObjectFromPath, type RouteMatchObject } from './route-matchers/old-route-matchers';