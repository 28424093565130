import { trackEvent } from 'utils/google-tag-manager';

/**
 * Higher-order function that wraps a function and tracks an event before execution
 *
 * @param eventName The name of the event to track
 * @param fn The function to wrap
 * 
 * @returns A new function that tracks the event and then executes the original function
 */
export function withEventTracking<T extends (...args: any[]) => any>(eventName: string, fn: T
): (...args: Parameters<T>) => ReturnType<T> {
  return (...args: Parameters<T>) => {
    trackEvent(eventName);
    return fn(...args);
  };
}