import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { getNumberOfPropertyTypesFiltered, getListingStatusLabel, getNumberOfLegacyPropertyTypesFiltered } from 'utils/listing-query-helper';
import AdvancedSearch from 'components/search-bar/advanced-search';
import Button from 'components/control/button';
import FilterDropdown from 'components/search-bar/advanced-search/filter-dropdown';
import FilterDropdownStatusOptions from 'components/search-bar/advanced-search/filter-dropdown/status-options';
import RadioButtonGroup from 'components/control/radio-button-group';
import FilterDropdownPropertyType from 'components/search-bar/advanced-search/filter-dropdown/home-type';
import FilterDropdownPriceRange from 'components/search-bar/advanced-search/filter-dropdown/price-range';
import FilterDropdownBedOptions from 'components/search-bar/advanced-search/filter-dropdown/bed-options';
import LocationSearch from 'components/location-search';
import { legacyPropertyTypeOptions, listingTypeSelectionOptions, propertyTypeOptions } from 'utils/select-options';
import formatPriceRange from 'utils/format-price';
import { useUserContext, useModalContext, useThemeContext, useFeaturesContext, SAVED_SEARCH_FILTERS_MODAL } from 'contexts';
import { PreferencesContext } from 'contexts/preferences';
import clickMapView from 'utils/redirect-to-map-view';
import { createSearchPredictionModel } from 'data/search-predictions';
import FilterIcon from 'components/icon/filter-icon';
import MapIcon from 'components/icon/map-icon';
import ListViewIcon from 'components/icon/list-view-icon';
import { formatQueryIntoURLQueryParams } from 'utils/listing-query-helper';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import styles from './style.module.scss';
import { CountryCodeList } from 'types/countries';
import { mapPageIds, searchFilterIds } from 'constants/test-constants';
import { trackEvent } from 'utils/google-tag-manager';

import type SearchPrediction from 'data/search-predictions';
import type Address from 'data/addresses';
import type { IPreferences } from 'contexts/preferences';
import type { ListingParams } from 'contexts/preferences/listing-params/types';
import type { SearchSuggestions } from 'components/suggested-location-dropdown';
import { ThemeNames } from 'types/themes';

interface Props {
  addresses: Address[];
  locationSearchHandler?: (data: any) => void;
  toggleSidePanelFullScreen?: () => void;
  isSidePanelFullScreen?: boolean;
  filters: ListingParams['filter'];
  setFilters: (filters: ListingParams['filter']) => void;
}

const AreaListingsPageFilters = ({ addresses, locationSearchHandler, toggleSidePanelFullScreen, isSidePanelFullScreen, filters, setFilters }: Props) => {
  const router = useRouter();
  const { isAuthenticated, siteLocation } = useUserContext();
  const { themeName } = useThemeContext();
  const { openModal } = useModalContext();
  const { features } = useFeaturesContext();
  const [isLocationDropdownActive, setIsLocationDropdownActive] = useState(false);
  const [activePrediction, setActivePrediction] = useState<SearchPrediction | SearchSuggestions>();
  const [locationQuery, setLocationQuery] = useState('');
  const { listingParams, addToRecentSearches } = useContext(PreferencesContext) as IPreferences;
  const [searchBarPlaceholder, setSearchBarPlaceholder] = useState<string>();
  const isOnMap = router.route === '/search';

  useEffect(() => {
    if (listingParams.filter.areaName && isOnMap) {
      setSearchBarPlaceholder(listingParams.filter.areaName.split(',')[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingParams.filter.areaName]);

  const handleSaveSearchClick = () => {
    if (isAuthenticated) {
      openModal('create-saved-search', { query: { ...listingParams, filter: { ...listingParams.filter, boundary: null }}});
    } else {
      openModal('login-registration');
    }
  };

  const handleLocationSearch = (searchResult: any) => {
    const slug = searchResult.id.split('location-')[1];
    if (!slug) {
      listingParams.setSlug('');
    }

    setSearchBarPlaceholder(searchResult.description.split(',')[0]);
    if (!searchResult.transitionToPath) {
      searchResult = createSearchPredictionModel(searchResult) as SearchPrediction;
    }

    if (locationSearchHandler && searchResult?.group === 'locations') {
      locationSearchHandler(searchResult);
    } else if (activePrediction && locationSearchHandler && !searchResult) {
      locationSearchHandler(activePrediction);
    } else if (searchResult) {
      searchResult.transitionToPath(listingParams, themeName as ThemeNames);
    } else if (activePrediction) {
      (activePrediction as SearchPrediction).transitionToPath(listingParams, themeName as ThemeNames);
    }

    // Since this old deprecated area page no changes needed to this addToRecentSearches
    if (searchResult) {
      addToRecentSearches(searchResult);
    } else if (activePrediction) {
      addToRecentSearches(activePrediction as SearchPrediction);
    }
    setIsLocationDropdownActive(false);
  };

  const redirectToMap = () => {
    clickMapView(listingParams, addresses, router, siteLocation===CountryCodeList.UNITED_STATES);
  };

  const renderMapListToggleButton = () => {
    const labelForToggle = isSidePanelFullScreen ? 'Map View' : 'List View';
    const iconForToggle = isSidePanelFullScreen ? MapIcon : ListViewIcon;
    const trackEventForToggle = () =>
      trackEvent(isSidePanelFullScreen ? 'UiSrchQfMapButClk' : 'UiSrchQfListButClk');
    if (listingParams.filter.slug) {
      return (
        <a
          href={`/${listingParams.filter.slug}-real-estate/filter?${formatQueryIntoURLQueryParams(filters)}`}
          onClick={trackEventForToggle}
        >
          <Button
            label={labelForToggle}
            Icon={iconForToggle}
            theme="secondary"
            testId={mapPageIds.mapListToggleButton}
          />
        </a>
      );
    } else {
      return (
        <Button
          label={labelForToggle}
          Icon={iconForToggle}
          theme="secondary"
          testId={mapPageIds.mapListToggleButton}
          onClick={() => {
            trackEventForToggle();
            toggleSidePanelFullScreen?.();
          }}
        />
      );
    }
  };

  return (
    <>
      <div className={styles.component}>
        <div className={styles['mobile-search']}>
          <AdvancedSearch />
        </div>
        <div className={styles['filters-container']}>
          <div className={styles['filters-left-container']}>
            <div className={styles['search-container']} ref={useOutsideClickHandler(() => setIsLocationDropdownActive(false))}>
              <LocationSearch
                locationQuery={locationQuery}
                setLocationQuery={setLocationQuery}
                isLocationDropdownActive={isLocationDropdownActive}
                setIsLocationDropdownActive={setIsLocationDropdownActive}
                setActivePrediction={setActivePrediction}
                onClick={handleLocationSearch}
                placeholder={searchBarPlaceholder}
              />
            </div>
            <FilterDropdown
              theme="dark"
              label={filters.rental ? 'Rent' : 'Buy'}
              testId={searchFilterIds.buyOrRentDropdownMap}
            >
              <RadioButtonGroup
                options={listingTypeSelectionOptions}
                value={filters.rental}
                onValueChange={value => setFilters({
                  ...filters,
                  rental: value,
                  priceMin: null,
                  priceMax: null,
                })}
              />
            </FilterDropdown>
            <FilterDropdown
              theme="dark"
              label={`${getListingStatusLabel(filters.status, filters.rental)} Listings`}
              testId={searchFilterIds.listingStatusDropdownMap}
            >
              <FilterDropdownStatusOptions
                value={filters.status}
                onValueChange={value => setFilters({ ...filters, status: value })}
                isRental={filters.rental}
              />
            </FilterDropdown>
            <div className={styles['middle-filters']}>
              <FilterDropdown
                theme="dark"
                label={`Home Type (${features.useLegacySearchFilter ? getNumberOfLegacyPropertyTypesFiltered(filters.homeType) : getNumberOfPropertyTypesFiltered(filters.homeType)})`}
                testId={searchFilterIds.homeTypeDropdownMap}
              >
                <FilterDropdownPropertyType
                  currentHomeTypeFilter={filters.homeType}
                  onValueChange={value => setFilters({ ...filters, homeType: value })}
                  propertyTypeList={features.useLegacySearchFilter ? legacyPropertyTypeOptions : propertyTypeOptions }
                />
              </FilterDropdown>
              <FilterDropdown
                theme="dark"
                label={formatPriceRange(filters.priceMin, filters.priceMax)}
                testId={searchFilterIds.priceRangeDropdownMap}
              >
                <FilterDropdownPriceRange
                  min={filters.priceMin}
                  max={filters.priceMax}
                  isRental={filters.rental}
                  onValueChange={(min, max) => {
                    if (min && max && min > max) {
                      setFilters({ ...filters, priceMin: min, priceMax: min });
                    } else {
                      setFilters({ ...filters, priceMin: min, priceMax: max });
                    }}
                  }
                />
              </FilterDropdown>
              <FilterDropdown
                theme="dark"
                label={`${filters.bedrooms} Beds`}
                testId={searchFilterIds.bedsDropdownMap}
              >
                <FilterDropdownBedOptions
                  bedrooms={filters.bedrooms}
                  onValueChange={value => setFilters({ ...filters, bedrooms: value })}
                />
              </FilterDropdown>
            </div>
            <span className={styles['padded-button']} >
              <Button
                Icon={FilterIcon}
                theme="primary"
                label="More Filters"
                onClick={() => {
                  trackEvent('UiSrchQfMoreButClk');
                  openModal(SAVED_SEARCH_FILTERS_MODAL, { filter: filters, onFiltersChanged: setFilters });
                }}
                testId={searchFilterIds.moreButtonMapPage}
              />
            </span>
            {isOnMap &&
              <div className={styles['save-search']}>
                <Button
                  theme="tertiary"
                  label="Save Search"
                  onClick={() => {
                    trackEvent('UiSrchQfSaveButClk');
                    handleSaveSearchClick();
                  }}
                  testId={searchFilterIds.saveSearchButtonMapPage}
                />
              </div>
            }
          </div>
          <div className={styles['filters-right-container']}>
            {toggleSidePanelFullScreen && renderMapListToggleButton()}
            {!isOnMap &&
              <Button
                Icon={MapIcon}
                theme="tertiary"
                label={'Map'}
                onClick={redirectToMap}
                testId={searchFilterIds.mapButtonAreaPage}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AreaListingsPageFilters;
