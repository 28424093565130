import {
  AREA_OVERVIEW_NAMED_CONTENT_TYPE,
  hasNamedContentWithType,
  INFRASTRUCTURE_NAMED_CONTENT_TYPE,
  TAX_GUIDE_NAMED_CONTENT_TYPE,
} from 'components/named-content';
import { testIds } from 'constants/test-constants';
import useActive from 'hooks/use-active';
import { useIsMobile } from 'hooks/use-size-class';
import { useMemo } from 'react';
import { AreaListingsPageCollapsibleGuide } from '.';
import styles from './style.module.scss';

//#region Types
export type AreaGuidesProps = {
  /** The heading of the area blurb. */
  areaBlurbHeading?: string;
  /** The blurb of the area. */
  areaBlurb?: string;
  /** The named content associated with the area. */
  namedContent?: string[] | null;
  /** The name of the area. */
  areaName: string;
  /**
   * The title of the area overview.
   *
   * NOTE: When in mobile mode, this will be used as the area overview title, otherwise it will be appended to the area name to form the area overview title.
   */
  areaOverviewTitle: string;
  /**
   * When true, the area overview will be expanded by default.
   *
   * default: false
   */
  isActive: boolean;
}
//#endregion

//#region Constants
const AreaGuideClassName = styles['area-guide'];
const AreaBlurbClassName = styles['area-blurb'];
const AreaBlurbHeadingClassName = styles['area-blurb-heading'];
const GuideWrapperClassName = styles['guide-wrapper'];
//#endregion

const AreaGuides = ({
  areaBlurbHeading,
  areaBlurb,
  namedContent,
  areaName,
  areaOverviewTitle,
  isActive = false,
}: AreaGuidesProps) => {
  const [isAreaOverviewExpanded, toggleAreaOverview] = useActive(isActive);
  const [isInfrastructureExpanded, toggleInfrastructure] = useActive(isActive);
  const [isTaxGuideExpanded, toggleTaxGuide] = useActive(isActive);
  const isMobile = useIsMobile();

  const hasAreaBlurbText = areaBlurbHeading?.trim().length > 0 && areaBlurb?.trim().length > 0;

  const hasAreaGuides = hasNamedContentWithType(namedContent, AREA_OVERVIEW_NAMED_CONTENT_TYPE);
  const hasInfrastructureGuide = hasNamedContentWithType(namedContent, INFRASTRUCTURE_NAMED_CONTENT_TYPE);
  const hasTaxGuide = hasNamedContentWithType(namedContent, TAX_GUIDE_NAMED_CONTENT_TYPE);

  const infrastructureTitle = useMemo(() => !isMobile ? `${areaName} Infrastructure` : 'Infrastructure', [areaName, isMobile]);
  const taxTitle = useMemo(() => !isMobile ? `${areaName} Tax Guide` : 'Tax Guide', [areaName, isMobile]);
  const overviewTitle = useMemo(() => !isMobile ? `${areaName} ${areaOverviewTitle}` : areaOverviewTitle, [areaName, areaOverviewTitle, isMobile]);

  return (
    <div className={GuideWrapperClassName} data-testid={testIds.areaGuides}>
      {hasAreaBlurbText && (
        <div className={AreaBlurbClassName}>
          <h2 className={AreaBlurbHeadingClassName}>{areaBlurbHeading}</h2>
          <div dangerouslySetInnerHTML={{ __html: areaBlurb! }} />
        </div>
      )}
      {hasAreaGuides && (
        <AreaListingsPageCollapsibleGuide
          title={overviewTitle}
          type={AREA_OVERVIEW_NAMED_CONTENT_TYPE}
          isActive={isAreaOverviewExpanded}
          onCollapsibleSectionClick={toggleAreaOverview}
          guideContent={namedContent}
          className={AreaGuideClassName}
          testId={testIds.cityGuide}
        />
      )}
      {hasInfrastructureGuide && (
        <AreaListingsPageCollapsibleGuide
          title={infrastructureTitle}
          type={INFRASTRUCTURE_NAMED_CONTENT_TYPE}
          isActive={isInfrastructureExpanded}
          onCollapsibleSectionClick={toggleInfrastructure}
          guideContent={namedContent}
          className={AreaGuideClassName}
          testId={testIds.infraGuide}
        />
      )}
      {hasTaxGuide && (
        <AreaListingsPageCollapsibleGuide
          title={taxTitle}
          type={TAX_GUIDE_NAMED_CONTENT_TYPE}
          isActive={isTaxGuideExpanded}
          onCollapsibleSectionClick={toggleTaxGuide}
          guideContent={namedContent}
          className={AreaGuideClassName}
          testId={testIds.taxGuide}
        />
      )}
    </div>
  );
};

export default AreaGuides;