import { testIds } from 'constants/test-constants';
import styles from './style.module.scss';
import { Breadcrumb } from './types';
import { BreadcrumbSegment } from './BreadcrumbSegment';

import type { createBreadcrumbs, generateBreadcrumbs } from './utils';

export type BreadcrumbsProps = {

  /**
   * The breadcrumbs to display.
   *
   * NOTE: Breadcrumbs can be created by using one of the factory functions:
   * - {@link createBreadcrumbs}
   * - {@link generateBreadcrumbs} (deprecated)
   */
  breadcrumbs: readonly Breadcrumb[];
}

export default function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  // const { originUrl } = useThemeContext();
  // const host = !originUrl?.startsWith('http') ? originUrl?.startsWith('localhost') ? `http://${originUrl}` : `https://${originUrl}` : originUrl;
  // const { listingParams } = usePreferencesContext();

  // // Make sure the breadcrumb links have the correct filter parameters so that the user's filters persist
  // const getBreadcrumbLinkWithFilters = (link: string, isNotAreaPage: boolean) => {
  //   // Listing parameters should only be added to area page urls, not address pages
  //   if (isNotAreaPage) {
  //     return link;
  //   }
  //   const fullUrlWithFilters = getTargetedUrl({ url: `${host}${link}`, filters: listingParams.filter, sort: listingParams.sort, pageNumber: 1 });
  //   return fullUrlWithFilters.href;
  // };
  return (
    <div className={styles.component} data-testid={testIds.defaultBreadcrumbs}>
      <div className={styles['layout-container']}>
        {breadcrumbs.map((level, index) => (
          <BreadcrumbSegment key={index} breadcrumb={level} showArrow={index < breadcrumbs.length - 1} />
        ))}
      </div>
    </div>
  );
}
