import { useCallback } from 'react';
import { useThemeContext } from 'contexts';
import { captureLastSearch } from 'pages/api/last-search/lastSearchApiClient';

import type { ListingParams } from 'contexts/preferences/listing-params/types';

/**
 * Hook to capture and store the user's last search parameters
 *
 * @returns A callback function that takes a userId and listing parameters to store the last search
 * @example
 * ```tsx
 * const captureLastSearch = useCaptureLastSearch();
 * captureLastSearch(user.id, listingParams);
 * ```
 */
export const useCaptureLastSearch = () => {
  const { themeName } = useThemeContext();

  return useCallback((userId: string | number, lastSearch: ListingParams) => {
    if (userId && userId !== null && lastSearch) {
      captureLastSearch({ userId, lastSearch, sourceTheme: themeName });
    }
  }, [themeName]);
};