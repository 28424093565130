import React from 'react';
import { AreaPageListing, SingleAddress } from '@zoocasa/go-search';
import NotFound404Page from 'components/404';
import {
  AREA_LISTINGS_ROUTE,
  AreaListingsPage,
  AreaListingsPageServerSideProps,
  AreaListingsPageViewModel,
  DeprecatedAreaListingsPage,
  DeprecatedAreaListingsPageServerSideProps,
  EMBER_ROUTE,
  EmberPage,
  EmberPageServerSidePropsType,
  generateOldRouteMatchObjectFromPath,
  getAreaListingsPageServerSideProps,
  getDeprecatedAreaListingsPageServerSideProps,
  NOT_FOUND_404_ROUTE,
  RouteMatchObject,
} from 'components/dynamic-page';
import HeadData, { HeadDataType } from 'components/head-data';
import Layout from 'components/layout';
import configJSON from 'config.json';
import { MLML_LEAD_COOKIE_NAME, MLML_LEAD_EXPIRE_DAYS } from 'constants/cookies';
import { FeaturesType } from 'contexts/features';
import Address from 'data/addresses';
import createListing from 'data/listing';
import { diff } from 'deep-object-diff';
import { ThemeNames as TenantNames, Themes as Tenants } from 'types/themes';
import { getStringFromRequestCookie } from 'utils/cookies';
import getExpListingByMls from 'utils/get-exp-listing-by-mls';
import {
  fetchAgentIdAndDestinationByMLMLSlug,
  fetchMLMLAgentDetailsById,
  postLeadAttribution,
} from 'utils/my-link-my-lead';
import { getRouteName } from 'utils/route-name';
import { createGetServerSideProps } from 'utils/shared-getServerSideProps';
import { isTenantExpCA, isTenantExpInService, isTenantExpUS } from 'utils/tenant/which-tenant';
import { v4 as uuid } from 'uuid';
import { createSearchApi } from 'data/search';
import { createAddressesApi, parsedAddressPathFromJson } from 'data/addresses';
import { createNamedContentsApi } from 'data/named-content';
import { createInsightsApi } from 'data/insights';
import { CountryCodeList } from 'types/countries';
import { getSiteLocationFromRequest } from 'utils/site-location';

import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import type { DynamicPageServerSideProps, NotFound404PageServerSideProps } from 'types/dynamic_page_types';
import { createSeoLinksApi } from '../data/seo-links';

type serverSidePropsType = DynamicPageServerSideProps<DeprecatedAreaListingsPageServerSideProps | AreaListingsPageServerSideProps | EmberPageServerSidePropsType | NotFound404PageServerSideProps>;

export const getServerSideProps = createGetServerSideProps<GetServerSidePropsResult<serverSidePropsType>>(async (context: GetServerSidePropsContext, features: FeaturesType, tenant: Tenants) => {
  let props: GetServerSidePropsResult<serverSidePropsType>;
  const path = '/' + (context.params?.dynamic as string[]).join('/');
  const fullUrl = context.resolvedUrl;
  const redirectPath = determineRedirectPathForUpdatedAreaPages(path, fullUrl);
  const storedSiteLocation = getSiteLocationFromRequest(context.req);
  const isUsSite = storedSiteLocation === CountryCodeList.UNITED_STATES;
  const isExpUSTenant = isTenantExpUS(tenant, features);
  const isExpCATenant = isTenantExpCA(tenant, features);
  const isExpInServiceTenant = isTenantExpInService(tenant, features);
  const isExpSite = isExpUSTenant || isExpCATenant || isExpInServiceTenant;

  //#region Redirects to be moved to the middleware

  // Redirect legacy eXp CA and US address pages, rule set in middleware-redirects/index.ts
  const mlsNo = context.query.mlsNo as string;
  const mlsName = context.query.mlsName as string;
  if (mlsNo && isExpSite && path === '/listing') {
    const addressPath = await getExpListingByMls(mlsNo, mlsName);
    if (addressPath) {
      return {
        redirect: {
          permanent: true,
          destination: addressPath,
        },
      };
    } else {
      return {
        redirect: {
          permanent: true,
          destination: '/search',
        },
      };
    }
  }

  // eXp Realty US redirect rules:
  if (isExpUSTenant || isExpInServiceTenant) {
    const mlmlFormat = '/link/mlml/';
    // Needed to match with old legacy URL formats such as /link/firstname_lastname/
    const legacyFormat = /^\/link\/\w+_\w+\//;

    // Extract mlml slug from either url format
    let mlmlSlug = '';
    if (path.startsWith(mlmlFormat)) {
      // Slice off the known prefix '/link/mlml/'
      mlmlSlug = path.slice(mlmlFormat.length);
    } else if (legacyFormat.test(path)) {
      // Remove the matched segment by replacing with ''
      mlmlSlug = path.replace(legacyFormat, '');
    }

    if (mlmlSlug) {
      try {
        const { permanent, agentUuid, destinationUrl } = await fetchAgentIdAndDestinationByMLMLSlug(mlmlSlug);

        const agentInfo = await fetchMLMLAgentDetailsById(agentUuid); // Get agent detail and set browser cookie
        const existingCookie = getStringFromRequestCookie(MLML_LEAD_COOKIE_NAME, context.req);
        let lead_uuid;

        if (existingCookie) {
          const cookieData = JSON.parse(existingCookie);
          lead_uuid = cookieData.lead_uuid || uuid(); // Use lead_uuid from cookie, or generate a new one if not present
        } else {
          lead_uuid = uuid(); // Generate a new lead_uuid if no cookie exists
        }

        const { websiteUUID } = configJSON;
        await postLeadAttribution(lead_uuid, websiteUUID.expRealtyUS, mlmlSlug);
        const cookieData = { ...agentInfo, lead_uuid: lead_uuid, website_uuid: websiteUUID.expRealtyUS };
        context.res.setHeader('Set-Cookie', `${MLML_LEAD_COOKIE_NAME}=${JSON.stringify(cookieData)}; Path=/; Max-Age=${MLML_LEAD_EXPIRE_DAYS * 24 * 60 * 60}; SameSite=Lax`);

        return {
          redirect: {
            permanent,
            destination: destinationUrl,
          },
        };
      } catch (error) {
        console.error('Error fetching agent data:', error);
      }

      return {
        redirect: {
          permanent: true,
          destination: '/search',
        },
      };
    }
  }

  if (context.query.page && isNaN(Number(context.query.page)) || Number(context.query.page) > 200) {
    return {
      redirect: {
        permanent: true,
        destination: '/404',
      },
    };
  }

  // Redirect old eXp /our-team content page to the new /explore-exp content page
  // Only issue it will redirect without query params
  if (path.startsWith('/our-team')) {
    return {
      redirect: {
        permanent: true,
        destination: path.replace('/our-team', '/explore-exp'),
      },
    };
  } else if (path.startsWith('/new-listings')) { // Deprecating /new-listings page
    if (isUsSite) {
      return {
        redirect: {
          permanent: true,
          destination: '/us-real-estate',
        },
      };
    } else {
      return {
        redirect: {
          permanent: true,
          destination: '/ca-real-estate',
        },
      };
    }
  }
  //#endregion

  if (redirectPath) {
    props = {
      redirect: {
        permanent: true,
        destination: redirectPath,
      },
    };
  } else {
    const routeName = await getRouteName(fullUrl);
    if (routeName === AREA_LISTINGS_ROUTE) {
      if (features?.useNewAreaPage) {
        const searchApi = createSearchApi();
        const addressesApi = createAddressesApi();
        const namedContentsApi = createNamedContentsApi();
        const insightsApi = createInsightsApi();
        const seoLinksApi = createSeoLinksApi(insightsApi);
        props = await getAreaListingsPageServerSideProps(context, features, tenant, searchApi, addressesApi, namedContentsApi, insightsApi, seoLinksApi);
      } else {
        props = await getDeprecatedAreaListingsPageServerSideProps(context);
      }
    } else if (routeName === EMBER_ROUTE && tenant === TenantNames.ZOOCASA) {
      props = {
        props: {
          routeName: EMBER_ROUTE,
          props: { headTags: null, features, tenant },
        },
      };
    } else {
      props = {
        props: {
          routeName: NOT_FOUND_404_ROUTE,
          props: { headTags: null, features, tenant },
        },
      };
    }
  }
  return props;
});

// Root component for the dynamic page. The content is inferred from the routeName and props.
export default function DynamicPage({ routeName, props }: serverSidePropsType) {
  return (
    <Layout routeName={routeName}>
      <DynamicPageContent routeName={routeName} props={props} />
    </Layout>
  );
}

// Memoized component for the AreaListingsPage.
const AreaListingsPageContent = React.memo(function ({ viewModel }: { viewModel: AreaListingsPageViewModel }) {
  return <AreaListingsPage viewModel={viewModel}/>;
}, (prevProps, nextProps) => {
  const filterDiff = diff(prevProps.viewModel, nextProps.viewModel);
  const propsAreEqual = (Object.keys(filterDiff).length === 0);
  return propsAreEqual;
});

// Memoized component for the HeadData component.
const HeadComponent = React.memo(function ({ headTags }: { headTags: HeadDataType }) {
  return <HeadData data={headTags} />;
}, (prevProps, nextProps) => {
  const filterDiff = diff(prevProps.headTags, nextProps.headTags);
  return (Object.keys(filterDiff).length === 0);
});

// Memoized component for the DynamicPageContent component.
const DynamicPageContent = React.memo(function ({ routeName, props }: serverSidePropsType) {
  const { features: { useNewAreaPage = true }, tenant } = props;

  if (routeName === AREA_LISTINGS_ROUTE) {
    if (useNewAreaPage) {
      const {
        requestedAddress: requestedAddressData,
        electedAddress: electedAddressData,
        listings: listingsData,
        ...viewModelData
      } = props as AreaListingsPageServerSideProps;

      const requestedAddress = parsedAddressPathFromJson(requestedAddressData);
      const electedAddress = SingleAddress.fromJSON(electedAddressData);
      /** We need to convert the listings data from a plain object back into an AreaPageListing Model */
      const listings = listingsData.map((listing: unknown) => AreaPageListing.fromJSON(listing));

      const viewModel: AreaListingsPageViewModel = {
        requestedAddress,
        electedAddress,
        listings,
        ...viewModelData,
      };
      return <>
        {props.headTags && <HeadComponent headTags={props.headTags} />}
        <AreaListingsPageContent viewModel={viewModel}/>
      </>;
    } else {
      const { headTags, params, heading, pageNumber, totalCount, totalPages, genericNamedContent, isCrawler, isCrawlerLighthouse, footerData, addressesData, listingsData, breadcrumbs } = props as DeprecatedAreaListingsPageServerSideProps;
      const addresses = addressesData.map( i => new Address(i));
      const listings = listingsData.map(i => new createListing(i));
      return <DeprecatedAreaListingsPage headTags={headTags} addresses={addresses} breadcrumbs={breadcrumbs} params={params} listings={listings} heading={heading} pageNumber={pageNumber} totalCount={totalCount} totalPages={totalPages} genericNamedContent={genericNamedContent} isCrawler={isCrawler} isCrawlerLighthouse={isCrawlerLighthouse} footerData={footerData} />;
    }
  } else if (routeName === EMBER_ROUTE && tenant === TenantNames.ZOOCASA) {
    return <>
      {props.headTags && <HeadData data={props.headTags} />}
      <EmberPage />
    </>;
  } else {
    return <>
      {props.headTags && <HeadData data={props.headTags} />}
      <NotFound404Page />
    </>;
  }
}, (prevProps, nextProps) => {
  const filterDiff = diff(prevProps, nextProps);
  const propsAreEqual = (Object.keys(filterDiff).length === 0);
  return propsAreEqual;
});


function determineRedirectPathForUpdatedAreaPages(path: string, fullUrl: string) {
  const routeMatchObject: RouteMatchObject = generateOldRouteMatchObjectFromPath(path);
  if (isOldCountryFallBackAreaPage(path)) {
    return '/ca-' + fullUrl.slice(1);
  }
  if (routeMatchObject.route === AREA_LISTINGS_ROUTE) {
    const { homeType, city, province, provinceCode, neighbourhood, street, secondaryFilterNeighbourhood, secondaryFilterStreet,
      filter: { rental }} = routeMatchObject;
    const secondaryFilter = routeMatchObject.secondaryFilter || secondaryFilterNeighbourhood || secondaryFilterStreet;
    const secondaryFilterUrl = secondaryFilter ? `/${secondaryFilter}` : '';
    const location = city ? `${city}-${provinceCode}-` : province ? `${province}-` : 'ca-';
    const homeTypeUrl = homeType ? homeType !== 'real-estate' ? `/${homeType}` : '' : '';
    const neighbourhoodUrl = neighbourhood ? `/${neighbourhood}` : '';
    const streetUrl = street ? `/${street}` : '';
    const statusUrl = generateStatusUrl(routeMatchObject);
    const rentUrl = rental ? '/for-rent' : '';

    if (isOldAreaUrlStructure(path)) {
      return `/${location}real-estate${neighbourhoodUrl}${streetUrl}${rentUrl}${statusUrl}${homeTypeUrl}${secondaryFilterUrl}`;
    }
  }
  return null;
}

function isOldAreaUrlStructure(path: string) {
  const urlParts = path.slice(1).split('/');
  const baseLevelAreaPageUrl = `/${urlParts[0]}`;

  // Only look in the base level url pages, like /toronto-on-real-estate-condos. If the string appears in the neighbourhood subdirectory, treat it was a neighbourhood
  if (/-for-rent|-condos|-townhouses|-open-houses|-sold-listings|-past-listings|^\/townhouses|^\/houses|^\/condos|^\/real-estate/i.test(baseLevelAreaPageUrl)) {
    return true;
  }
  if (path.match(/^(?!.*open).*-houses/)) {
    return true;
  }
  return false;
}

function isOldCountryFallBackAreaPage(path: string) { // old: /real-estate; new: /ca-real-estate.
  return path.startsWith('/real-estate');
}

function generateStatusUrl(routeMatchObject: RouteMatchObject) {
  const { listingStatus, filter: { rental }} = routeMatchObject;
  if (listingStatus === 'sold-listings') {
    return rental ? '/leased' : '/sold';
  } else if (listingStatus === 'past-listings') {
    return rental ? '/expired-listings' : '/past-listings';
  // } else if (listingStatus === 'pending-listings') {
  //   return '/pending';
  }
  return '';
}
