import { getCmsHost } from 'utils/host-config';
import { AboutUsContent, AboutUsNamedContent, HomeConfigContent, HomeConfigNamedContent, NamedContent } from './types';
import { fetchNamedContents } from './fetcher';
import { PropertyTypeFilter } from 'contexts/preferences/listing-params/types';
import { getTargetedUrlPropertyTypeSelection, HOMES_TARGETED_URL_PROPERTY_TYPE } from 'utils/listing-query-helper';

export type NamedContentsApiType = {

  /**
   * Fetches named contents associated with the given key.
   *
   * @param key The key of the named contents to fetch.
   * @param signal An optional AbortSignal to cancel the request.
   * @returns A promise that resolves to the named contents. Returns undefined otherwise.
   */
  getNamedContents<T>(key: string, signal?: AbortSignal): Promise<NamedContent<T>[] | undefined>

  /**
   * Fetches the named contents associated with the given slug and property type.
   *
   * This is used to fetch the named content for the area page.
   *
   * @param slug The slug of the area page to fetch.
   * @param propertyType The property type being filtered by on the area page.
   * @param signal An optional AbortSignal to cancel the request.
   * @returns A promise that resolves to the named contents. Returns undefined otherwise.
   */
  getAreaPageNamedContent(slug: string, propertyType?: PropertyTypeFilter, signal?: AbortSignal): Promise<NamedContent[] | undefined>

  /**
   * Fetches the named contents associated with configuration of the home page.
   *
   * It provides a list of featured homes and a google review rating.
   *
   * @param signal An optional AbortSignal to cancel the request.
   * @returns A promise that resolves to the home config named content. Returns undefined otherwise.
   */
  getHomeConfigNamedContent(signal?: AbortSignal): Promise<HomeConfigNamedContent | undefined>
}
class NamedContentsApi implements NamedContentsApiType {

  private constructor(private readonly host: string) { }


  /** Creates a new NamedContentsApi instance with the given host. */
  static create(host: string = getCmsHost()): NamedContentsApi {
    return new NamedContentsApi(host);
  }

  /**
   * Fetches named contents associated with the given key.
   *
   * @param key The key of the named contents to fetch.
   * @param signal An optional AbortSignal to cancel the request.
   * @returns A promise that resolves to the named contents. Returns undefined otherwise.
   */
  async getNamedContents<T>(key: string, signal?: AbortSignal): Promise<NamedContent<T>[] | undefined> {
    return fetchNamedContents<T>(key, signal, this.host);
  }

  /**
   * Fetches the named contents associated with the given slug and property type.
   *
   * This is used to fetch the named content for the area page.
   *
   * @param slug The slug of the area page to fetch.
   * @param propertyType The property type being filtered by on the area page. If not provided, no filter is applied to the named content.
   * @param signal An optional AbortSignal to cancel the request.
   * @returns A promise that resolves to the named contents. Returns undefined otherwise.
   */
  async getAreaPageNamedContent(slug: string, propertyType?: PropertyTypeFilter, signal?: AbortSignal): Promise<NamedContent[] | undefined> {
    const targetedUrlPropertyType = propertyType ? getTargetedUrlPropertyTypeSelection(propertyType) : HOMES_TARGETED_URL_PROPERTY_TYPE;

    let key: string;

    if (targetedUrlPropertyType === HOMES_TARGETED_URL_PROPERTY_TYPE) {
      key = `locations-${slug}`;
    } else {
      key = `locations-${slug}-${targetedUrlPropertyType.toLowerCase()}`;
    }
    return fetchNamedContents<string>(key, signal, this.host);
  }

  async getHomeConfigNamedContent(signal?: AbortSignal): Promise<HomeConfigNamedContent | undefined> {
    const homeConfig = await fetchNamedContents<HomeConfigContent>('config-home', signal, this.host);
    if (!homeConfig || homeConfig.length === 0 ) {
      return undefined;
    }
    return homeConfig[0];
  }

  async getAboutUsNamedContent(signal?: AbortSignal): Promise<AboutUsNamedContent | undefined> {
    const aboutUs = await fetchNamedContents<AboutUsContent>('zoocasa-aboutus', signal, this.host);
    if (!aboutUs || aboutUs.length === 0 ) {
      return undefined;
    }
    return aboutUs[0];
  }
}

/** Creates a new NamedContentsApi instance with the given host. */
export const createNamedContentsApi = NamedContentsApi.create;
