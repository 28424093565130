export enum BreadcrumbType {
    BREADCRUMB_TYPE_COUNTRY = 0,
    BREADCRUMB_TYPE_PROVINCE_OR_STATE = 1,
    BREADCRUMB_TYPE_SUB_DIVISION = 2,
    BREADCRUMB_TYPE_NEIGHBOURHOOD = 3,
    BREADCRUMB_TYPE_MULTI_UNIT = 4,
    BREADCRUMB_TYPE_ADDRESS = 5,
    BREADCRUMB_TYPE_STATIC = 6,
    BREADCRUMB_TYPE_UNKNOWN = -1,
}

/**
 * A breadcrumb is a link that represents a part of the address hierarchy.
 */
export type Breadcrumb = {
    /** The name of the breadcrumb used as the display text. */
    name: string;
    /** The link to navigate to when the breadcrumb is clicked. */
    link: string;
    /** The type of the breadcrumb. */
    type: BreadcrumbType;
};