const noImageListingsFilterAckKey = 'noImageListingsFilterAck';
const noImageListingsFilterAckExpiryKey = 'noImageListingsFilterAckExpiry';
const maxAge = 90 * 24 * 60 * 60 * 1000;

export function setNoImageListingsFilterAck(ack: boolean) {
  const expiry = Date.now() + maxAge;
  localStorage.setItem(noImageListingsFilterAckKey, JSON.stringify(ack));
  localStorage.setItem(noImageListingsFilterAckExpiryKey, expiry.toString());
}

export function hasNoImageListingsFilterBeenAck() {
  const expiry = localStorage?.getItem(noImageListingsFilterAckExpiryKey);
  const noImageListingsFilterAck = localStorage?.getItem(noImageListingsFilterAckKey);

  if (expiry && Date.now() > parseInt(expiry, 10)) {
    clearNoImageListingsFilterAck(); // Clear if expired
    return false;
  }

  if (noImageListingsFilterAck && noImageListingsFilterAck.trim().length > 0) {
    return JSON.parse(noImageListingsFilterAck) as boolean;
  }

  return false;
};

export const clearNoImageListingsFilterAck = () => {
  localStorage.removeItem(noImageListingsFilterAckKey);
  localStorage.removeItem(noImageListingsFilterAckExpiryKey);
};
