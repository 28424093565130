import { useCallback } from 'react';
import { useModalContext, useThemeContext, useUserContext } from 'contexts';
import { handleQuebecPopup, IsUserInQuebec } from 'utils/modals';
import { QuebecPopupSource } from 'components/modal-dialog/quebec-popup';

/**
 * Hook to handle showing the Quebec popup when user is not in Quebec but views Quebec-related content
 *
 * @returns A callback function that takes a boolean indicating if the content is Quebec-related and the source
 * @example
 * ```tsx
 * const showQuebecPopup = useShowQuebecPopup();
 * const hasQuebecContent = requestedAddress.provinceOrState?.slug === provinceOrStateSlugFromCode('qc');
 *
 * useEffect(() => {
 *   showQuebecPopup(hasQuebecContent, 'area');
 * }, [hasQuebecContent]);
 * ```
 */
export const useShowQuebecPopup = () => {
  const { themeName } = useThemeContext();
  const { openModal } = useModalContext();
  const { userLocation } = useUserContext();

  return useCallback((
    hasQuebecContent: boolean,
    source: QuebecPopupSource
  ) => {
    const isUserNotInQuebec = !IsUserInQuebec(userLocation);
    if (hasQuebecContent && isUserNotInQuebec) {
      handleQuebecPopup({ themeName, isQuebec: true, openModal, source });
    }
  }, [themeName, openModal, userLocation]);
};