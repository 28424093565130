import { SortBy } from '@zoocasa/go-search';
import { 
  BATHROOMS_DESCENDING_SORT,
  BATHROOMS_SORT,
  BEDROOMS_DESCENDING_SORT,
  BEDROOMS_SORT,
  DATE_DESCENDING_SORT,
  DATE_SORT,
  PRICE_DESCENDING_SORT,
  PRICE_SORT,
  type Sort,
} from 'contexts/preferences/listing-params/types';

/**
 * Get the {@link SortBy} enum value for a given sort string.
 * 
 * @param sort The {@link Sort} string to convert to a {@link SortBy} enum value.
 * @returns The {@link SortBy} enum value corresponding to the sort string.
 */
export function getSortBy(sort: Sort): SortBy {
  switch (sort) {
  case PRICE_DESCENDING_SORT:
    return SortBy.PriceDesc;
  case PRICE_SORT:
    return SortBy.PriceAsc;
  case DATE_DESCENDING_SORT:
    return SortBy.DateDesc;
  case DATE_SORT:
    return SortBy.DateAsd;
  case BEDROOMS_DESCENDING_SORT:
    return SortBy.BedroomsDesc;
  case BEDROOMS_SORT:
    return SortBy.BedroomsAsc;
  case BATHROOMS_DESCENDING_SORT:
    return SortBy.BathroomsDesc;
  case BATHROOMS_SORT:
    return SortBy.BathroomsAsc;
  }
} 